import * as React from "react"
import { getColor, colors } from "../theme/colors";

//level can be "empty", "low", "medium" or "high"
const ThermometerIcon = ({ level, levelColor, envelopColor }) => {
    const color = levelColor || "grey";
    // Initialize fillY and fillHeight based on the level
    let fillY = 0;
    let fillHeight = 0;
    if (level === "low") {
        fillY = 190;
        fillHeight = 120;
    } else if (level === "medium") {
        fillY = 110;
        fillHeight = 200;
    } else if (level === "high") {
        fillY = 10;
        fillHeight = 300;
    }
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 330">
            <defs>
                <clipPath id="thermometerClip">
                    <path
                        d="M100,10 
                 Q80,10 80,25 
                 L80,200 
                 A60,60 0 1 0 130,200 
                 L130,25 
                 Q130,10 100,10 
                 Z"
                    />
                </clipPath>
            </defs>

            {/* Fill the thermometer */}
            <rect
                x="50"
                y={fillY}
                width="110"
                height={fillHeight}
                fill={color}
                clipPath="url(#thermometerClip)"
            />

            {/* Draw the thermometer outline */}
            <path
                d="M100,10 
             Q80,10 80,25 
             L80,200 
             A60,60 0 1 0 130,200 
             L130,25 
             Q130,10 100,10 
             Z"
                fill="none"
                stroke={envelopColor}
                strokeWidth="15"
            />

            {/* Graduations */}
            {/* Low Level */}
            <line
                x1="0"
                y1="190"
                x2="50"
                y2="190"
                stroke={envelopColor}
                strokeWidth="10"
            />
            {/* Medium Level */}
            <line
                x1="0"
                y1="110"
                x2="50"
                y2="110"
                stroke={envelopColor}
                strokeWidth="10"
            />
            {/* High Level */}
            <line
                x1="0"
                y1="30"
                x2="50"
                y2="30"
                stroke={envelopColor}
                strokeWidth="10"
            />
        </svg>
    );
};

export default ThermometerIcon