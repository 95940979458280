
import { alpha } from '@mui/material/styles';

const colors = {
    orange: {
        main: '#ff9e29',
        tint1: "#f7aa4b",
        tint2: "#f9d0a0",
        tint3: "#fce4c7",
    },
    forest: {
        main: '#127d78',
        tint1: "#20b3aa",
        tint2: "#9fd5da",
        tint3: "#cce8ec",
    },
    azure: {
        main: '#2e96cc',
        tint1: '#6ab5dd',
        tint2: '#abd5ec',
        tint3: '#ddedf6',
    },
    greenLemon: {
        main: "#8fb81f",
        tint1: "#bad157",
        tint2: "#e3ebba",
        tint3: "#f1f5dc",
    },
    pink: {
        main: "#ee4068",
        tint1: "#eb5a7e",
        tint2: "#f3a2b7",
        tint3: "#fad9e1"
    },
    grey: {
        text: "#83888f", //used for text on background
        icons: "#565d69", //used for icons
        componentBackground: "#dee2e6", //used for components background
        background: "#f2f4f6", //used for background
    }
}

const createShades = (color, opacity) => alpha(color, opacity);

const getColor = (color, opacity = 1) => {
    return opacity === 1 ? color : createShades(color, opacity);
}


export { colors, getColor };

// export default new Colors();