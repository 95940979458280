import { colors, getColor } from "../theme/colors";

const fillColor = getColor(colors.greenLemon.main);

const HappyFace = ({ text }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 48 46" //set viewBox to 0 0 svgXSize svgYSize and svg can be size by parent with CSS
            style={{ display: "block" }} //and set display to block !
        >
            <path
                fill="#8FB81F"
                d="M33.016 4.202A12.457 12.457 0 1 0 15.4 21.818 12.458 12.458 0 1 0 33.016 4.202ZM20.204 10.34a1.335 1.335 0 1 1 0 2.67 1.335 1.335 0 0 1 0-2.67Zm9.325 5.904c-.668 2.24-2.792 3.883-5.318 3.883s-4.65-1.642-5.323-3.883a.445.445 0 0 1 .435-.566h9.77a.444.444 0 0 1 .436.566Zm-1.317-3.235a1.335 1.335 0 1 1 0-2.67 1.335 1.335 0 0 1 0 2.67Z"
            />
            <rect width={47} height={19} x={0.708} y={26.51} fill={fillColor} rx={9.5} />
            <text
                x="50%" // Position the text
                y="37px" // Position the text
                dominantBaseline="middle" // Vertically center the text
                textAnchor="middle" // Horizontally center the text
                fill="#fff" // Text color
                fontSize="16" // Text size, adjust as needed
            >
                {text}
            </text>
        </svg>
    );
};

export default HappyFace;