//*********************************************************************************************************************************************//
//                                                               REACT imports                                                                 //
//*********************************************************************************************************************************************//
import { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";

//*********************************************************************************************************************************************//
//                                                                 Mui imports                                                                 //
//*********************************************************************************************************************************************//
import AppBar from '@mui/material/AppBar';
import { styled, useTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Autocomplete from "@mui/material/Autocomplete";
import Popover from '@mui/material/Popover';
import Modal from "@mui/material/Modal";
//--------------------------------------------------------------------icons--------------------------------------------------------------------//
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import LogoutIcon from '@mui/icons-material/Logout';

//*********************************************************************************************************************************************//
//                                                         external libraries imports                                                          //
//*********************************************************************************************************************************************//
import { useIdleTimer } from 'react-idle-timer';

//*********************************************************************************************************************************************//
//                                                              PROVIDERS imports                                                              //
//*********************************************************************************************************************************************//
import { useUser } from "../context/UserProvider";
import { useHttp } from "../context/HttpProvider";

//*********************************************************************************************************************************************//
//                                                             COMPONENTS imports                                                              //
//*********************************************************************************************************************************************//
import { StyledAppBar } from "./StyledAppBar";

//*********************************************************************************************************************************************//
//                                                               DIVERS imports                                                                //
//*********************************************************************************************************************************************//
import { getIdleTimeout, getPromptTimeout } from "../config";


let countdown;

const IdleModal = () => {
    //providers
    const { sendData } = useHttp();
    const { logoutUser, isLoggedIn, fetch_user_and_establishments } = useUser();
    const { customer_hid } = useParams();

    //states
    const [openModal, setOpenModal] = useState(false);
    const [remainingTime, setRemainingTimeState] = useState(undefined);
    // const [startTime, setStartTime] = useState(undefined);
    const startTime = useRef(null);
    const PROMPT_TIMEOUT = getPromptTimeout();
    const IDLE_TIMEOUT = getIdleTimeout();

    //effects
    useEffect(() => {
        pause();
        if (!isLoggedIn) return;
        start();
        document.addEventListener("visibilitychange", onVisibilityChange);
        return () => {
            document.removeEventListener("visibilitychange", onVisibilityChange);
        }
    }, [isLoggedIn])

    //functions

    const setRemainingTime = (time) => {
        if (time === 0) {
            clearInterval(countdown);
            setRemainingTimeState(PROMPT_TIMEOUT);
        } else {
            setRemainingTimeState(time);
        }
    }

    const onVisibilityChange = () => {
        if (document.visibilityState === "visible") {
            //check if modal should be opened
            let remaining = PROMPT_TIMEOUT * 1000 - Date.now() + startTime.current;
            if (remaining > 1000) {
                //browser set low execution priority when tab / window not visible, that affects timeInterval that counts intervals slower than normally
                //So we update remaining time before we open modal
                setRemainingTime(Math.round(remaining / 1000));
                setOpenModal(true);
            }
        } else if (document.visibilityState === "hidden") {
            setOpenModal(false);
            // clearInterval(countdown);
        }
    }

    const onPrompt = () => {
        if (document.visibilityState === "visible") setOpenModal(true);
        setRemainingTime(PROMPT_TIMEOUT);
        let tmp = Date.now();
        // setStartTime(tmp);
        startTime.current = tmp;
        countdown = setInterval(() => {
            setRemainingTime(remainingTime => remainingTime - 1);
        }, 1000)
    }

    const onIdle = () => {
        setOpenModal(false);
        clearInterval(countdown);
        logoutUser();
    }

    const { start, pause } = useIdleTimer({
        onPrompt,
        onIdle,
        timeout: IDLE_TIMEOUT * 1000, //total time before onIdle is called
        promptBeforeIdle: PROMPT_TIMEOUT * 1000, //how much time before timeout onPrompt is called
        events: [
            'mousemove',
            'keydown',
            'touchstart',
            'touchmove'
        ],
        immediateEvents: [],
        debounce: 0,
        throttle: 0,
        eventsThrottle: 200,
        element: document,
        startOnMount: true,
        startManually: false,
        stopOnIdle: false,
        crossTab: false,
        name: 'idle-timer',
        syncTimers: 0,
        leaderElection: false
    })

    if (!isLoggedIn) pause();

    return (
        <div>
            <Modal
                open={openModal}
                onClose={(event, reason) => {
                    if (reason && reason === "backdropClick") return;
                    setOpenModal(false)
                }}
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    minWidth: 400,
                    minHeight: 400,
                    width: "auto",
                    height: "auto",
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    overflowY: "auto"
                }}>
                    <StyledAppBar>
                        <Toolbar sx={{ display: "flex", flexDirection: "row", gap: "10px", justifyContent: "space-between" }}>
                            <Box sx={{ display: "flex", alignItems: "center", flexGrow: "1" }}>
                                <Typography variant="h6">
                                    Inactivité
                                </Typography>
                            </Box>
                        </Toolbar>
                    </StyledAppBar>
                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", padding: "64px 8px 8px 8px" }}>
                        <Typography variant="h6" component="div">
                            Vous êtes inactif depuis {Math.floor(IDLE_TIMEOUT / 60)} minutes.
                        </Typography>
                        <Typography variant="h6" component="div" sx={{ marginBottom: "24px" }}>
                            Déconnexion dans <span style={{ color: "red" }}>{remainingTime}</span> secondes.
                        </Typography>
                        <Button variant="contained" onClick={async () => {
                            setOpenModal(false);
                            fetch_user_and_establishments(customer_hid);
                            start();
                            clearInterval(countdown);
                        }}>Rester connecté</Button>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}

export { IdleModal }