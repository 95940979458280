
import HappyFace from "../../img/HappyFace";
import NeutralFace from "../../img/NeutralFace";
import SadFace from "../../img/SadFace";


const GradeSmiley = ({ grade }) => {
    if (!grade) return null;
    let smileyIcon = undefined;
    if (grade < 2) {
        smileyIcon = <SadFace text={grade?.toFixed(1)} />
    }
    else if (grade < 3) {
        smileyIcon = <NeutralFace text={grade?.toFixed(1)} />
    }
    else {
        smileyIcon = <HappyFace text={grade?.toFixed(1)} />
    }

    return (
        <>
            {smileyIcon}
        </>
    );
}


export { GradeSmiley }