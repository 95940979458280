
/**
 * 
 * @param {string} fill possible values are 0, 1, 2
 */
const PaqStatusIcon = ({ color, fillLevel }) => {
    let iconColor = color ? color : "grey";
    let filledWidth = "0%";
    if (fillLevel === 1) {
        filledWidth = "50%";
    } else if (fillLevel === 2) {
        filledWidth = "100%";
    }
    return (
        <div
            style={{
                width: "100%",
                aspectRatio: "1 / 1",
                borderRadius: "50%",
                border: `1px solid ${iconColor}`,
                overflow: "hidden",
            }}
        >
            <div
                style={{
                    width: `${filledWidth}`,
                    height: "100%",
                    backgroundColor: `${iconColor}`
                }}
            />
        </div>
    );
}


export default PaqStatusIcon;