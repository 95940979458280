//*********************************************************************************************************************************************//
//                                                               REACT imports                                                                 //
//*********************************************************************************************************************************************//
import { useState, useEffect, useMemo, useRef, useLayoutEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

//*********************************************************************************************************************************************//
//                                                                 Mui imports                                                                 //
//*********************************************************************************************************************************************//
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import ToggleButton from '@mui/material/ToggleButton';
import Tooltip from "@mui/material/Tooltip";
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';


//--------------------------------------------------------------------icons--------------------------------------------------------------------//
import EditIcon from '@mui/icons-material/Edit';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { ImperativeSign } from "../eval/ImperativeSign";
import PersonIcon from '@mui/icons-material/Person';
import ThermometerIcon from "../../icons/ThermometerIcon";
import PaqStatusIcon from "../../icons/PaqStatusIcon";
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';

//*********************************************************************************************************************************************//
//                                                         external libraries imports                                                          //
//*********************************************************************************************************************************************//
import {
    MaterialReactTable,
    useMaterialReactTable,
    getMRT_RowSelectionHandler,
    MRT_ToggleDensePaddingButton,
    MRT_ShowHideColumnsButton,
    MRT_ToggleFullScreenButton,
    MRT_ToolbarInternalButtons
} from 'material-react-table';
import { MRT_Localization_FR } from 'material-react-table/locales/fr';
import { isWithinInterval, parseISO } from "date-fns";

//*********************************************************************************************************************************************//
//                                                              PROVIDERS imports                                                              //
//*********************************************************************************************************************************************//
import { useUser } from "../../context/UserProvider";

//*********************************************************************************************************************************************//
//                                                             COMPONENTS imports                                                              //
//*********************************************************************************************************************************************//
import { PaqEntryDialog } from "./PaqEntryDialog";
import { PaqEntriesDialog } from "./PaqEntriesDialog";

//*********************************************************************************************************************************************//
//                                                               DIVERS imports                                                                //
//*********************************************************************************************************************************************//
import { getElapsedTimeInDays, getDateString, fromArrayToObject } from '../../lib/lib';
import { colors, getColor } from "../../theme/colors";

//*********************************************************************************************************************************************//
//                                                               MODELS imports                                                                //
//*********************************************************************************************************************************************//
import { PaqEntry } from '../../models/Paq';

import "./PaqComponent.css";
import GridHelper from "../../helpers/GridHelper";




const TopToolbarRenderer = ({ table, view, setView, pc }) => {
    const { user, lists } = useUser();
    let filters = table.getState().columnFilters;
    let columnCI = table.getColumn("imperative_criterion");
    let columnAssignee = table.getColumn("assignee");
    let columnStatus = table.getColumn("status");
    const handleExportData = async () => {
        await GridHelper.exportTableToExcel(table);
    }

    const isCISelected = filters.find(e => e.id === "imperative_criterion")?.value === "oui";
    const assigneeFilterValue = filters.find(e => e.id === "assignee")?.value?.toLowerCase();
    const isAssigneeSelected = assigneeFilterValue === `${user?.firstname} ${user?.lastname}`.toLowerCase();
    const statusFilterValue = filters.find(e => e.id === "status")?.value;
    const status1Label = lists?.STATUS_PAQ_LIST?.find(e => e.value === "1")?.label;
    const status2Label = lists?.STATUS_PAQ_LIST?.find(e => e.value === "2")?.label;
    const isToDoSelected = statusFilterValue?.length === 2 && statusFilterValue?.includes(status1Label) && statusFilterValue?.includes(status2Label);

    return (
        <Box sx={{
            display: "flex", alignItems: "center", gap: "8px"
        }}>
            <ToggleButtonGroup
                value={view}
                exclusive
                onChange={(event, newValue) => setView(oldValue => newValue || oldValue)}
            >
                <Tooltip title='Vue condensée " Small "'>
                    <ToggleButton value="small" size="small" selected={view === "small"}>
                        <Box sx={{ width: "24px", height: "24px" }}>
                            <Typography
                                style={{
                                    fontSize: "18px",
                                    fontWeight: view === "small" ? "bold" : "normal",
                                }}
                            >S</Typography>
                        </Box>
                    </ToggleButton>
                </Tooltip>
                <Tooltip title='Vue condensée " Medium "'>
                    <ToggleButton value="medium" size="small" selected={view === "medium"}>
                        <Box sx={{ width: "24px", height: "24px" }}>
                            <Typography
                                style={{
                                    fontSize: "18px",
                                    fontWeight: view === "medium" ? "bold" : "normal",
                                }}
                            >M</Typography>
                        </Box>
                    </ToggleButton>
                </Tooltip>
                <Tooltip title='Vue condensée " Large "'>
                    <ToggleButton value="large" size="small" selected={view === "large"}>
                        <Box sx={{ width: "24px", height: "24px" }}>
                            <Typography
                                style={{
                                    fontSize: "18px",
                                    fontWeight: view === "large" ? "bold" : "normal",
                                }}
                            >L</Typography>
                        </Box>
                    </ToggleButton>
                </Tooltip>
                <Tooltip title='Vue détaillée " Full "'>
                    <ToggleButton value="full" size="small" selected={view === "full"}>
                        <Box sx={{ width: "24px", height: "24px" }}>
                            <Typography
                                style={{
                                    fontSize: "18px",
                                    fontWeight: view === "full" ? "bold" : "normal",
                                }}
                            >F</Typography>
                        </Box>
                    </ToggleButton>
                </Tooltip>
            </ToggleButtonGroup>
            <ToggleButtonGroup>
                <Tooltip title={isToDoSelected ? "Désactiver filtrer actions à faire seulement" : "Activer filtrer actions à faire seulement"}>
                    < ToggleButton
                        size="small"
                        value="toDoFilter"
                        selected={isToDoSelected}
                        onChange={() => {
                            columnStatus.setFilterValue(isToDoSelected ? "" : [status1Label, status2Label]);
                        }}
                    >
                        <Box sx={{ width: "24px", height: "24px" }}><PaqStatusIcon color={pc.statusGreen} fillLevel={1} /></Box>
                    </ToggleButton>
                </Tooltip >
                <Tooltip title={isCISelected ? "Désactiver filtrer par critères impératifs" : "Activer filtrer par critères impératifs"}>
                    < ToggleButton
                        size="small"
                        value="impCritFilter"
                        selected={isCISelected}
                        onChange={() => {
                            columnCI.setFilterValue(isCISelected ? "" : "oui");
                        }}
                    >
                        <Box sx={{ width: "24px", height: "24px" }}><ImperativeSign /></Box>
                    </ToggleButton>
                </Tooltip >
                <Tooltip title={isAssigneeSelected ? "Désactiver filtrer mes actions" : "Activer filtrer mes actions"}>
                    < ToggleButton
                        size="small"
                        value="assigneeFilter"
                        selected={isAssigneeSelected}
                        onChange={() => {
                            columnAssignee.setFilterValue(isAssigneeSelected ? "" : `${user?.firstname} ${user?.lastname}`);
                        }}
                    >
                        <Box sx={{ width: "24px", height: "24px" }}><PersonIcon /></Box>
                    </ToggleButton>
                </Tooltip >
            </ToggleButtonGroup>
            {/* <Divider orientation="vertical" variant="middle" flexItem /> */}
            <Tooltip title="Exporter vers excel">
                <IconButton
                    onClick={handleExportData}
                >
                    <CloudDownloadIcon />
                </IconButton>
            </Tooltip>
        </Box >
    )
}

const BottomToolbarRenderer = ({ table, establishment_id, setPaqEntryDialog, setPaqEntriesDialog }) => {
    const { lists, isAuthorized } = useUser();
    let canWrite = isAuthorized({
        module: lists?.LICENSE_MODULE_VALUES["PAQ"],
        baseAction: lists?.BASE_ACTION_VALUES["WRT"],
    });
    let selectedRows = table.getSelectedRowModel().rows;
    const totalRows = table.getFilteredRowModel().rows.length;
    return (
        <Box sx={{ display: "flex", width: "100%", height: "100%", justifyContent: "space-between", alignItems: "center" }}>
            <Typography>Nombre d'actions: {totalRows}</Typography>
            <Box sx={{ display: "flex", justifyContent: "end", gap: "12px" }}>
                {canWrite &&
                    <Button
                        onClick={() => setPaqEntriesDialog({ isOpen: true, editedPaqEntries: selectedRows.map(e => new PaqEntry({ ...e.original, establishment_id: establishment_id, assignee: e.original?.assignee?._id })), title: `Éditer ${selectedRows.length} actions` })}
                        variant="contained"
                        disabled={selectedRows?.length < 2}
                    >
                        Éditer sélection ({selectedRows.length})
                    </Button>
                }
                {canWrite &&
                    <Button
                        onClick={() => setPaqEntryDialog({ isOpen: true, paqEntry: new PaqEntry({ clientAction: 'new', establishment_id: establishment_id }), title: 'Nouvelle action' })}
                        variant="contained"
                    >
                        Nouvelle action
                    </Button>
                }
            </Box>
        </Box>
    );
}

const PaqComponent = ({ paqEntries, setPaqEntries, establishment_id, mode, initialFilters, isPaqLoaded }) => {
    const theme = useTheme();
    const { lists, isAuthorized } = useUser();
    const paqEntryDialogEmptyState = { isOpen: false, paqEntry: undefined, title: '' }
    const [paqEntryDialog, setPaqEntryDialog] = useState(paqEntryDialogEmptyState);
    const paqEntriesDialogEmptyState = { isOpen: false, editedPaqEntries: undefined, title: '' }
    const [paqEntriesDialog, setPaqEntriesDialog] = useState(paqEntriesDialogEmptyState);
    const boxContainerRef = useRef(null);
    const hasInitialFiltersBeenSet = useRef(false);
    const [view, setView] = useState("small"); //small, medium, large, full

    const listPaq = useMemo(() => {
        let list = {
            objective: fromArrayToObject(lists.OBJECTIVE_PAQ_LIST, "value"),
            source: fromArrayToObject(lists.SOURCE_PAQ_LIST, "value"),
            status: fromArrayToObject(lists.STATUS_PAQ_LIST, "value"),
            environmental_demand: fromArrayToObject(lists.ENVIRONMENTAL_DEMAND_PAQ_LIST, "value"),
            information_system_handling: fromArrayToObject(lists.INFORMATION_SYS_PAQ_LIST, "value"),
            activity_handling: fromArrayToObject(lists.ACTIVITY_HANDLING_PAQ_LIST, "value"),
            people_risk: fromArrayToObject(lists.PEOPLE_RISK_PAQ_LIST, "value"),
            customer_care: fromArrayToObject(lists.CUSTOMER_CARE_PAQ_LIST, "value"),
            frequency: fromArrayToObject(lists.FREQUENCY_PAQ_LIST, "value"),
            occurence: fromArrayToObject(lists.OCCURENCE_PAQ_LIST, "value"),
            detectability: fromArrayToObject(lists.DETECTABILITY_PAQ_LIST, "value"),
            job: fromArrayToObject(lists.JOB_LIST, "value")
        }
        return list;
    }, [lists])

    const [columnFilters, setColumnFilters] = useState([{ id: "status", value: [listPaq.status?.[1]?.label, listPaq.status?.[2]?.label] }]);


    const pc = useMemo(() => ({
        emergencyRed: getColor(colors.pink.main),
        emergencyGreen: getColor(colors.greenLemon.main),
        emergencyOrange: getColor(colors.orange.main),
        emergencyGrey: getColor(colors.grey.text),
        criticityRed: getColor(colors.pink.main),
        criticityOrange: getColor(colors.orange.main),
        criticityGreen: getColor(colors.greenLemon.main),
        criticityGrey: getColor(colors.grey.text),
        statusGreen: getColor(colors.greenLemon.main),
        statusGrey: getColor(colors.grey.icons)
    }), [])


    // Determine the view based on the container width
    const determineView = (width) => {
        if (width >= 1300) return 'large';
        if (width >= 950) return 'medium';
        return 'small';
    };

    //update view based on container width
    useLayoutEffect(() => {
        const updateView = () => {
            if (boxContainerRef.current) {
                const width = boxContainerRef.current.getBoundingClientRect().width;
                const newView = determineView(width);
                if (newView !== view) {
                    setView(newView);
                }
            }
        };
        updateView();

    }, []);


    const getFilterSelectOptions = (accessKey) => {
        let arr = [];
        if (!listPaq) {
            return arr;
        }
        for (let key in listPaq[accessKey]) {
            arr.push(listPaq[accessKey][key].label);
        }
        return arr;
    }

    const dateFilterSelectOptions = useMemo(() => {
        let arr = [
            "dernier mois",
            "3 derniers mois",
            "6 derniers mois",
            "12 derniers mois",
            "prochain mois",
            "3 prochains mois",
            "6 prochains mois",
            "12 prochains mois"
        ];
        return arr;
    }, [])

    //if initialFilters is provided, setColumnFilters state accordingly, but only for first time value is defined
    useEffect(() => {
        if (Array.isArray(initialFilters) && hasInitialFiltersBeenSet.current === false) {
            setColumnFilters(initialFilters);
            hasInitialFiltersBeenSet.current = true;
        }
    }, [initialFilters])

    //return start and en dates depending on filterValue
    const getDatesInterval = (filterValue) => {
        let startInterval = new Date();
        let endInterval = new Date();
        switch (filterValue) {
            case "dernier mois":
                startInterval.setMonth(endInterval.getMonth() - 1);
                break;
            case "3 derniers mois":
                startInterval.setMonth(endInterval.getMonth() - 3);
                break;
            case "6 derniers mois":
                startInterval.setMonth(endInterval.getMonth() - 6);
                break;
            case "12 derniers mois":
                startInterval.setMonth(endInterval.getMonth() - 12);
                break;
            case "prochain mois":
                endInterval.setMonth(startInterval.getMonth() + 1);
                break;
            case "3 prochains mois":
                endInterval.setMonth(startInterval.getMonth() + 3);
                break;
            case "6 prochains mois":
                endInterval.setMonth(startInterval.getMonth() + 6);
                break;
            case "12 prochains mois":
                endInterval.setMonth(startInterval.getMonth() + 12);
                break;
        }
        startInterval.setHours(0, 0, 0, 0);
        endInterval.setHours(23, 59, 59, 999);
        return { startInterval, endInterval };
    }

    const dateFilterFunction = (row, id, filterValue) => {
        const { startInterval, endInterval } = getDatesInterval(filterValue);
        return isWithinInterval(parseISO(row.original[id]), { start: startInterval, end: endInterval });
    }

    const getDelayStrings = (delay) => {
        if (!delay) return ["", ""];
        const absDelay = Math.abs(delay);
        if (absDelay < 90) {
            return [`${absDelay}j`, `${absDelay} jours`];
        } else if (absDelay < 366) {
            const absDelayM = Math.floor(absDelay / 30.42);
            return [`${absDelayM}m`, `${absDelayM} mois`];
        } else {
            return [`+1a`, "plus d'un an"];
        }
    }

    const getEmergencyIcon = (delay) => {
        const width = "52px";
        const height = "30px";
        const isLate = delay > 0;
        const isSoonLate = !isLate && delay > -7;
        const delayString = getDelayStrings(delay);

        if (!delay) {
            return (
                <Tooltip title="échéance non renseignée">
                    <Box sx={{
                        width: width,
                        height: height,
                        p: "0 6px",
                        border: `1px solid ${pc.emergencyGrey}`,
                        borderRadius: "8px",
                        boxSizing: "border-box",  /* Include border in size calculation */
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }
                    }>
                        <HourglassEmptyIcon sx={{ color: pc.emergencyGrey, marginLeft: "-6px" }} />
                        <Typography sx={{ fontSize: "14px", color: pc.emergencyGrey }}>? </Typography>
                    </Box>
                </Tooltip>
            );
        } else {
            if (!isLate && !isSoonLate) {
                return (
                    <Tooltip title={`échéance dans ${delayString[1]}`}>
                        <Box sx={{
                            width: width,
                            height: height,
                            p: "0 6px",
                            border: `1px solid ${pc.emergencyGreen}`,
                            borderRadius: "8px",
                            boxSizing: "border-box",  /* Include border in size calculation */
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }
                        }>
                            <HourglassTopIcon sx={{ color: pc.emergencyGreen, marginLeft: "-6px", marginRight: "-4px" }} />
                            <Typography sx={{ fontSize: "14px", color: pc.emergencyGreen }}>{delayString[0]}</Typography>
                        </Box>
                    </Tooltip>
                );
            } if (!isLate && isSoonLate) {
                return (
                    <Tooltip title={`échéance dans ${delayString[1]}`}>
                        <Box sx={{
                            width: width,
                            height: height,
                            p: "0 6px",
                            border: `1px solid ${pc.emergencyOrange}`,
                            borderRadius: "8px",
                            boxSizing: "border-box",  /* Include border in size calculation */
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }
                        }>
                            <HourglassTopIcon sx={{ color: pc.emergencyOrange, marginLeft: "-6px", marginRight: "-4px" }} />
                            <Typography sx={{ fontSize: "14px", color: pc.emergencyOrange }}>{delayString[0]}</Typography>
                        </Box>
                    </Tooltip>
                );
            }
            else {
                return (
                    <Tooltip title={`en retard de ${delayString[1]}`}>
                        <Box sx={{
                            width: width,
                            height: height,
                            p: "0 6px",
                            border: `1px solid ${pc.emergencyRed}`,
                            borderRadius: "8px",
                            boxSizing: "border-box",  /* Include border in size calculation */
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }
                        }>
                            <HourglassBottomIcon sx={{ color: pc.emergencyRed, marginLeft: "-6px", marginRight: "-4px" }} />
                            <Typography sx={{ fontSize: "14px", color: pc.emergencyRed }}>{delayString[0]}</Typography>
                        </Box>
                    </Tooltip>
                )
            }
        }
    }

    const getCriticityColor = (criticity) => {
        if (criticity > 100) return pc.criticityRed;
        if (criticity > 40) return pc.criticityOrange;
        if (criticity > 40) return pc.criticityGreen;
        return pc.criticityGrey;
    }

    const getCriticityLevel = (criticity) => {
        if (criticity > 100) return "high";
        if (criticity > 40) return "medium";
        if (criticity > 0) return "low";
        return "empty";
    }

    const getCriticityIcon = (criticity) => {
        const criticityColor = getCriticityColor(criticity);
        const criticityLevel = getCriticityLevel(criticity);
        const envelopColor = criticity ? "black" : criticityColor;
        return (
            <Tooltip title={`${criticity ? "score de criticité" : "criticité non renseignée"}`}>
                <Box sx={{
                    width: "52px",
                    height: "30px",
                    p: "0 6px",
                    border: `1px solid ${criticityColor}`,
                    borderRadius: "8px",
                    boxSizing: "border-box",  /* Include border in size calculation */
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }
                }>
                    <Box sx={{ width: "14px", display: "flex", alignItems: "flex-end", marginLeft: "-2px" }}><ThermometerIcon level={criticityLevel} levelColor={criticityColor} envelopColor={envelopColor} /></Box>
                    <Typography sx={{ fontSize: "14px", color: criticityColor }}>{criticity ? criticity : "? "}</Typography>
                </Box>
            </Tooltip>
        )
    }

    const getStatusIcon = (status) => {
        let fillLevel;
        let color = pc.statusGreen;
        switch (status) {
            case "1":
                fillLevel = 0;
                break;
            case "2":
                fillLevel = 1;
                break;
            case "3":
                fillLevel = 2;
                break;
            case "4":
                fillLevel = 2;
                color = pc.statusGrey;
                break;
            default:
                fillLevel = 0;
                break;
        }
        return (
            <Tooltip title={listPaq?.status?.[status]?.label}>
                <Box
                    sx={{
                        border: `1px solid ${color}`,
                        borderRadius: "8px",
                        width: "52px",
                        height: "30px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                    }}>
                    <Box sx={{ width: "24px", height: "24px" }}>
                        <PaqStatusIcon fillLevel={fillLevel} color={color} />
                    </Box>
                </Box>
            </Tooltip>
        );
    }

    const stateSortingFn = (rowA, rowB) => {
        const statusA = rowA.original["status"];
        const statusB = rowB.original["status"];
        const criticityA = rowA.original["criticity"];
        const criticityB = rowB.original["criticity"];
        const delayA = rowA.original["delay"];
        const delayB = rowB.original["delay"];

        //first sort by status "1" and "2" are first, "3" are second "4" are last
        const statusOrder = {
            1: 1,
            2: 1,
            3: 2,
            4: 3,
        };
        let sortedByStatus = (statusOrder[statusB] || 10) - (statusOrder[statusA] || 10);
        if (sortedByStatus) return sortedByStatus;

        //then sort by criticity
        if (criticityA && criticityB) {
            let sortedByCriticity = criticityA - criticityB;
            if (sortedByCriticity) return sortedByCriticity;
        }
        if (!criticityA && criticityB) {
            return -1;
        }
        if (criticityA && !criticityB) {
            return 1;
        }

        //then sort by delay
        if (delayA && delayB) {
            let sortedByDelay = delayA - delayB;
            return sortedByDelay;
        }
        if (!delayA && delayB) {
            return -1;
        }
        return 1;

    }

    const columns = useMemo(() => {
        const defaultColumns = {
            imperative_criterion: {
                id: 'imperative_criterion',
                accessorFn: (row) => row?.imperative_criterion ? "oui" : "non",
                Header: () => <Tooltip title="Critère impératif"><Box sx={{ width: "20px", height: "20px" }}><ImperativeSign /></Box></Tooltip>,
                header: "Critère impératif",
                Cell: ({ cell }) => cell.getValue() === "oui" ? <Box sx={{ width: "24px", height: "24px" }}><ImperativeSign /></Box> : <></>,
                enableSorting: false,
                enableColumnFilter: false,
                enableColumnDragging: false,
                enableColumnFilterModes: false,
                size: 40,
                grow: false,
                muiTableHeadCellProps: {
                    sx: {
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",

                    },
                },
                enableGlobalFilter: false
            },
            action_number: {
                id: 'action_number',
                accessorFn: (row) => row?.action_number || "",
                header: 'N°',
                enableGrouping: false,
                enableEditing: false,
                enableColumnFilterModes: false,
                filterFn: "equals",
                size: 100,
                grow: false,
            },
            objective: {
                id: 'objective',
                accessorFn: (row) => listPaq.objective?.[row?.objective]?.label || "",
                header: 'Objectif',
                filterVariant: "multi-select",
                filterSelectOptions: getFilterSelectOptions("objective"),
                enableColumnFilterModes: false,
                size: 200,
            },
            action: {
                id: 'action',
                accessorFn: (row) => row?.action || "",
                Cell: ({ renderedCellValue }) => <Box sx={{
                    display: '-webkit-box',
                    overflow: 'hidden',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: 10,
                    textAlign: "left"
                }}>{renderedCellValue}</Box>,
                header: 'Action',
                enableGrouping: false,
                enableColumnFilterModes: false,
                filterFn: "contains",
                size: 350,
            },
            assignee: {
                id: 'assignee',
                accessorFn: (row) => {
                    return `${row?.assignee?.firstname || ""} ${row?.assignee?.lastname || ""}\n${listPaq?.job?.[row?.assignee?.job]?.label || ""}`;
                },
                header: 'Pilote',
                enableColumnFilterModes: false,
                size: 150,
            },
            state: {
                id: 'state',
                accessorFn: (row) => {
                    const statusString = row?.status ? `statut: ${listPaq?.status?.[row?.status]?.label}\n` : "";
                    const criticityString = row?.criticity ? `criticité: ${row?.criticity}\n` : "";
                    const delayString = row?.delay ? `délai: ${row?.delay}` : "";
                    return statusString + criticityString + delayString;
                },
                Cell: ({ row }) => {
                    const statusIcon = row?.original?.status ? getStatusIcon(row?.original?.status) : <></>;
                    const isStatusDone = ["3", "4"].includes(row?.original?.status);
                    const criticityIcon = row?.original?.criticity ? getCriticityIcon(row?.original?.criticity) : isStatusDone ? <></> : getCriticityIcon(row?.original?.criticity);
                    const delayIcon = row?.original?.delay ? getEmergencyIcon(row?.original?.delay) : isStatusDone ? <></> : getEmergencyIcon(row?.original?.delay);
                    return (
                        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "4px" }}>
                            {statusIcon}
                            {criticityIcon}
                            {delayIcon}
                        </Box>
                    );
                },
                sortingFn: stateSortingFn,
                header: 'État',
                enableGrouping: false,
                enableColumnFilterModes: false,
                enableColumnFilter: false,
                enableColumnFilterModes: false,
                enableGlobalFilter: false,
                size: 120,
            },
            source: {
                id: 'source',
                accessorFn: (row) => {
                    if (!Array.isArray(row?.source)) {
                        return [];
                    }
                    let str = "";
                    let i = 1;
                    for (let value of row.source) {
                        let tmp = listPaq.source[value]?.label;
                        if (tmp) {
                            if (i < row.source.length) str += tmp + ", ";
                            else str += tmp;
                        }
                        i++;
                    }
                    return str;
                },
                header: 'Source',
                filterVariant: "multi-select",
                filterSelectOptions: getFilterSelectOptions("source"),
                enableColumnFilterModes: false,
                size: 160,
            },
            status: {
                id: 'status',
                accessorFn: (row) => listPaq.status[row?.status]?.label || "",
                Cell: ({ row }) => {
                    if (!row?.original?.status) return <></>;
                    const statusIcon = getStatusIcon(row?.original?.status);
                    return (
                        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "4px" }}>
                            {statusIcon}
                        </Box>
                    );
                },
                header: 'Statut',
                filterVariant: "multi-select",
                filterSelectOptions: getFilterSelectOptions("status"),
                enableColumnFilterModes: false,
                enableGlobalFilter: false,
                size: 130,
            },
            due_date: {
                id: 'due_date',
                accessorFn: (row) => getDateString(row?.due_date) || "",
                header: 'Échéance',
                enableGrouping: false,
                enableColumnFilterModes: false,
                filterVariant: "select",
                filterSelectOptions: dateFilterSelectOptions,
                filterFn: "customDateFilterFunction",
                size: 160,
            },
            start_date: {
                id: 'start_date',
                accessorFn: (row) => getDateString(row?.start_date) || "",
                header: 'Début',
                enableGrouping: false,
                enableColumnFilterModes: false,
                filterVariant: "select",
                filterSelectOptions: dateFilterSelectOptions,
                filterFn: "customDateFilterFunction",
                size: 160,
            },
            completion_date: {
                id: 'completion_date',
                accessorFn: (row) => getDateString(row?.completion_date) || "",
                header: 'Clôture',
                enableGrouping: false,
                enableColumnFilterModes: false,
                filterVariant: "select",
                filterSelectOptions: dateFilterSelectOptions,
                filterFn: "customDateFilterFunction",
                size: 160,
            },
            delay: {
                id: 'delay',
                accessorFn: (row) => row?.delay,
                Cell: ({ row }) => {
                    //if status is abandonned, there is no delay 
                    if (row?.original?.status === "4") return <></>;
                    const delayIcon = getEmergencyIcon(row?.original?.delay);
                    return (
                        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "4px" }}>
                            {delayIcon}
                        </Box>
                    );
                },
                header: 'Délai',
                enableGrouping: false,
                enableColumnFilterModes: false,
                filterFn: "greaterThan",
                enableGlobalFilter: false,
                size: 130
            },
            reason: {
                id: 'reason',
                accessorFn: (row) => row?.reason || "",
                header: 'Raison',
                enableGrouping: false,
                enableColumnFilterModes: false,
                filterFn: "contains",
                size: 160,
            },
            criticity: {
                id: 'criticity',
                accessorFn: (row) => row?.criticity || "",
                Cell: ({ row }) => {
                    const isStatusDone = ["3", "4"].includes(row?.original?.status);
                    const criticityIcon = row?.original?.criticity ? getCriticityIcon(row?.original?.criticity) : isStatusDone ? <></> : getCriticityIcon();
                    return (
                        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "4px" }}>
                            {criticityIcon}
                        </Box>
                    );
                },
                header: 'Criticité',
                enableGrouping: false,
                enableColumnFilterModes: false,
                filterFn: "greaterThan",
                enableGlobalFilter: false,
                size: 140
            },
            created: {
                id: 'created',
                accessorFn: (row) => {
                    return `${row?.created?.by?.firstname || ""} ${row?.created?.by?.lastname || ""}\n${getDateString(row?.created?.on) || ""}`;
                },
                header: 'Créée',
                enableGrouping: false,
                enableColumnFilterModes: false,
                size: 160,
            },
            updated: {
                id: 'updated',
                accessorFn: (row) => {
                    return `${row?.created?.by?.firstname || ""} ${row?.created?.by?.lastname || ""}\n${getDateString(row?.created?.on) || ""}`;
                },
                header: 'Mis à jour',
                enableGrouping: false,
                enableColumnFilterModes: false,
                size: 160,
            },
            comment: {
                id: 'comment',
                accessorFn: (row) => row?.comment || "",
                header: 'Commentaires',
                enableGrouping: false,
                enableColumnFilterModes: false,
                filterFn: "contains",
                size: 250
            }
        }
        if (view === "full") {
            return [
                defaultColumns["imperative_criterion"],
                defaultColumns["action_number"],
                defaultColumns["objective"],
                defaultColumns["action"],
                defaultColumns["assignee"],
                defaultColumns["state"],
                defaultColumns["source"],
                defaultColumns["status"],
                defaultColumns["due_date"],
                defaultColumns["start_date"],
                defaultColumns["completion_date"],
                defaultColumns["delay"],
                defaultColumns["reason"],
                defaultColumns["criticity"],
                defaultColumns["created"],
                defaultColumns["updated"],
                defaultColumns["comment"],
            ];
        } else {
            // Condensed View (depending on screen size)
            if (view === 'large') {
                return [
                    defaultColumns["imperative_criterion"],
                    defaultColumns["action_number"],
                    {
                        ...defaultColumns["objective"],
                        size: 130,
                        grow: true,
                    },
                    {
                        ...defaultColumns["action"],
                        size: 250,
                        grow: true,
                    },
                    {
                        ...defaultColumns["assignee"],
                        size: 150,
                        grow: false
                    },
                    {
                        ...defaultColumns["state"],
                        size: 120,
                        grow: false,
                    },
                    {
                        ...defaultColumns["source"],
                        size: 160,
                        grow: false
                    },
                    {
                        ...defaultColumns["status"],
                        size: 130,
                        grow: false,
                        visibleInShowHideMenu: false, //hidden, here to keep required order in other views (order cannot change once paq is loaded)
                        enableGlobalFilter: false
                    },
                    {
                        ...defaultColumns["due_date"],
                        size: 160,
                        grow: false
                    },
                ];
            } else if (view === 'medium') {
                return [
                    defaultColumns["imperative_criterion"],
                    defaultColumns["action_number"],
                    {
                        ...defaultColumns["objective"],
                        size: 130,
                        grow: true
                    },
                    {
                        ...defaultColumns["action"],
                        size: 250,
                        grow: true
                    },
                    {
                        ...defaultColumns["assignee"],
                        size: 120,
                        grow: true
                    },
                    {
                        ...defaultColumns["state"],
                        size: 120,
                        grow: false,
                        enableGlobalFilter: false
                    },
                    {
                        ...defaultColumns["source"],
                        visibleInShowHideMenu: false, //hidden, here to keep required order in other views (order cannot change once paq is loaded)
                        enableGlobalFilter: false
                    },
                    {
                        ...defaultColumns["status"],
                        visibleInShowHideMenu: false, //hidden, for toggle filter only
                        enableGlobalFilter: false
                    },
                ];
            } else if (view === 'small') {
                return [
                    defaultColumns["imperative_criterion"],
                    defaultColumns["action_number"],
                    {
                        ...defaultColumns["objective"],
                        size: 130,
                        grow: true,
                    },
                    {
                        ...defaultColumns["action"],
                        size: 250,
                        grow: true,
                    },
                    {
                        ...defaultColumns["assignee"],
                        visibleInShowHideMenu: false, //hidden, for toggle filter only
                        enableGlobalFilter: false
                    },
                    {
                        ...defaultColumns["state"],
                        visibleInShowHideMenu: false, //hidden, here to keep required order in other views (order cannot change once paq is loaded)
                        enableGlobalFilter: false
                    },
                    {
                        ...defaultColumns["source"],
                        visibleInShowHideMenu: false, //hidden, here to keep required order in other views (order cannot change once paq is loaded)
                        enableGlobalFilter: false
                    },
                    {
                        ...defaultColumns["status"],
                        visibleInShowHideMenu: false, //hidden, for toggle filter only
                        enableGlobalFilter: false
                    },
                ];
            }
        }
    }, [listPaq, view]);


    const table = useMaterialReactTable({
        columns,
        data: isPaqLoaded ? paqEntries : [],
        layoutMode: "grid", //If use grid-no-grow, there's a fantom column that appears on the right. If use grid, grow values are ignored, can only set true or false.... with V2
        enableStickyHeader: true,
        enableColumnOrdering: true,
        globalFilterFn: 'contains', //turn off fuzzy matching and use simple contains filter function. CAREFUL: Ensure all cell values are either valid or have a fallback value that can use .toString() to avoid crashes!
        enableGrouping: false,
        columnFilterDisplayMode: "popover",
        enableRowSelection: isAuthorized({
            module: lists?.LICENSE_MODULE_VALUES["PAQ"],
            baseAction: lists?.BASE_ACTION_VALUES["WRT"],
        }) ? true : false,
        muiTableBodyRowProps: ({ row, staticRowIndex, table }) => ({//enable row selection y a click anywhere on the line, outside the checkbox
            onClick: (event) =>
                getMRT_RowSelectionHandler({ row, staticRowIndex, table })(event), //import this helper function from material-react-table
            sx: {
                cursor: isAuthorized({
                    module: lists?.LICENSE_MODULE_VALUES["PAQ"],
                    baseAction: lists?.BASE_ACTION_VALUES["WRT"],
                }) ? 'pointer' : 'auto'
            },
        }),
        positionToolbarAlertBanner: 'hide', //hide alert toolbar that shows up when any row is selected
        enableEditing: isAuthorized({
            module: lists?.LICENSE_MODULE_VALUES["PAQ"],
            baseAction: lists?.BASE_ACTION_VALUES["WRT"],
        }),
        enableColumnPinning: true,
        enableColumnResizing: true,
        enableColumnFilterModes: true,
        enableFullScreenToggle: mode === "eval" ? true : false,
        filterFns: {
            customDateFilterFunction: (row, id, filterValue) => dateFilterFunction(row, id, filterValue),
        },
        editDisplayMode: "modal",
        enableDensityToggle: false,
        initialState: {
            density: 'compact',
            columnPinning: { right: ["mrt-row-actions"] },
            sorting: [{ id: "action_number", desc: true }]
        },
        state: {
            showSkeletons: !isPaqLoaded,
            columnFilters: columnFilters,
            columnVisibility: {
                "assignee": (view === "small") ? false : true,
                "status": (["small", "medium", 'large'].includes(view)) ? false : true,
                "state": (["small"].includes(view)) ? false : true,
                "source": (["small", "medium"].includes(view)) ? false : true,
            },
        },
        onColumnFiltersChange: setColumnFilters,
        displayColumnDefOptions: {
            'mrt-row-actions': {
                header: 'Editer', // change "Actions" to "Editer"
                grow: false,
                Cell: ({ row }) => (
                    <IconButton
                        onClick={(event) => {
                            event.preventDefault();
                            event.stopPropagation();
                            setPaqEntryDialog({
                                isOpen: true,
                                title: `Modifier action n°${row?.original?.action_number}`,
                                paqEntry: { ...row.original },
                            })
                        }}
                    >
                        <EditIcon />
                    </IconButton>
                ),
            },
        },
        muiTableBodyCellProps: { align: "center", style: { whiteSpace: "pre-wrap" } }, //style: { whiteSpace: "pre-wrap" } gives the possibility to make \n returned by accessorFn make a line break
        renderEmptyRowsFallback: () => (
            <div style={{
                width: "200px",
                textAlign: "center",
                position: "sticky",
                left: `calc(${boxContainerRef?.current?.offsetWidth / 2}px - 100px)`,
            }}>
                <h3><em>{paqEntries.length > 0 ? 'Aucun résultat' : `Plan d'actions vide`}</em></h3>
            </div>
        ),
        enableColumnActions: false,
        localization: MRT_Localization_FR,
        enablePagination: false,
        enableRowVirtualization: true,
        rowVirtualizerOptions: {
            overscan: 0, // adjust the number or rows that are rendered above and below the visible area of the table
            estimateSize: () => 2 * paqEntries?.length || 0, //if your rows are taller than normal, try tweaking this value to make scrollbar size more accurate
        },
        renderTopToolbarCustomActions: ({ table }) => <TopToolbarRenderer table={table} view={view} setView={setView} pc={pc} />,
        renderBottomToolbarCustomActions: ({ table }) => <BottomToolbarRenderer table={table} establishment_id={establishment_id} setPaqEntryDialog={setPaqEntryDialog} setPaqEntriesDialog={setPaqEntriesDialog} />,
        muiTableContainerProps: ({ table }) => ({
            sx: {
                maxHeight: mode === "eval" ?
                    `calc(100vh - ${theme.appbarHeight} - ${table.refs.topToolbarRef.current?.offsetHeight}px - ${table.refs.bottomToolbarRef.current?.offsetHeight}px - 64px - 64px)`
                    :
                    `calc(100vh - ${theme.appbarHeight} - ${table.refs.topToolbarRef.current?.offsetHeight}px - ${table.refs.bottomToolbarRef.current?.offsetHeight}px)`,
            },
        }),
        muiTableHeadCellProps: {
            align: "justify",
        },
        // muiTableHeadCellProps: {
        //     sx: {
        //         //use the `&` syntax to target nested elements by their class
        //         '& .Mui-TableHeadCell-Content': {
        //             color: getColor(colors.forest.main),
        //         },
        //         '& .MuiSvgIcon-root': {
        //             color: getColor(colors.forest.main),
        //             '&.css-i4bv87-MuiSvgIcon-root': {
        //                 color: "blue"
        //             },
        //             '&:hover': {
        //                 color: getColor(colors.forest.tint1), // Hover color
        //             },
        //         }
        //     },
        // },
        // muiTableBodyCellProps: {
        //     sx: {
        //         borderBottom: '1px solid #e0e0e0', //add a border between columns
        //     },
        // },
    });


    return (
        <Box ref={boxContainerRef} >
            <MaterialReactTable table={table} />
            {paqEntryDialog?.paqEntry &&
                <PaqEntryDialog
                    isOpen={paqEntryDialog.isOpen}
                    onClose={() => setPaqEntryDialog(paqEntryDialogEmptyState)}
                    title={paqEntryDialog.title}
                    paqEntry={paqEntryDialog.paqEntry}
                    setPaqEntries={(val) => setPaqEntries(val)}
                />
            }
            {paqEntriesDialog?.editedPaqEntries?.length > 0 &&
                <PaqEntriesDialog
                    isOpen={paqEntriesDialog.isOpen}
                    onClose={() => setPaqEntriesDialog(paqEntriesDialogEmptyState)}
                    title={paqEntriesDialog.title}
                    editedPaqEntries={paqEntriesDialog.editedPaqEntries}
                    setPaqEntries={(val) => setPaqEntries(val)}
                    listPaq={listPaq}
                />
            }
        </Box>
    );
}

export { PaqComponent }