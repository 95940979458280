import { Routes, Route, Navigate, useLocation, Outlet } from "react-router-dom";
import Box from '@mui/material/Box';
import { useUser } from "./context/UserProvider";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Paq from "./pages/Paq/Paq";
import EvalList from "./pages/eval/EvalList";
import EvalDashboard from "./pages/eval/EvalDashboard/EvalDashboard";
import EvalCriterion from "./pages/eval/evalCriterion/EvalCriterion";
import EvalCockpit from "./pages/eval/evalCockpit/EvalCockpit";
import { AppToolbar } from "./components/Toolbar";
import { IdleModal } from "./components/IdleModal";
import { LoginModal } from "./components/LoginModal";
import { usePaths } from "./context/PathsProvider";
import UpdatePwd from "./pages/UpdatePwd";

import "./theme/global.css";
import { ErrorFallback } from "./components/ErrorFallback";


const RequireAuth = ({ children }) => {
    const {
        isUserLoading,
        isLoggedIn,
        loginFlag,
        customer_hid,
        isUserPwdTemporary,
        selectedEstablishment,
        lists
    } = useUser();
    const { paths } = usePaths();
    const location = useLocation();

    let content = null;

    if (isUserLoading && !isLoggedIn && loginFlag) {
        content = null;
    } else if (!isLoggedIn && loginFlag) {
        content = <Navigate to={paths.login.app({ customer_hid })} />;
    } else if (!isLoggedIn) {
        content = (
            <>
                <LoginModal isOpen />
                {children}
            </>
        );
    } else if (isUserPwdTemporary && location.pathname !== `/${customer_hid}/update_pwd`) {
        content = <Navigate to={paths.updatePwd.app({ customer_hid })} />;
    } else if (!isUserPwdTemporary && location.pathname === `/${customer_hid}/update_pwd`) {
        content = (
            <Navigate
                to={paths.home.app({
                    customer_hid,
                    establishment_id: selectedEstablishment?._id,
                })}
            />
        );
    } else if (
        [`/${customer_hid}`, `/${customer_hid}/`, `/${customer_hid}/app`, `/${customer_hid}/app/`].includes(
            location.pathname
        )
    ) {
        content = (
            <Navigate
                to={paths.home.app({
                    customer_hid,
                    establishment_id: selectedEstablishment?._id,
                })}
            />
        );
    } else if (!isUserLoading && isLoggedIn && lists) {
        content = (
            <>
                <IdleModal />
                {children}
            </>
        );
    }
    else {
        content = null;
    }

    return <>{content}</>;
};


const RoutesAuthorizationChecker = ({ accesses, fallback }) => {

    const { isAuthorized } = useUser();
    let isAuthorizedBool = isAuthorized({ accesses: accesses });

    return isAuthorizedBool ? <Outlet /> : (<>{fallback}</> || null);
};

//fallback component when child Route is not rendered because Authorization was not granted
const RouteFallback = () => {
    return (
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100%" }}>
            Vous n'avez pas accès à cette ressource.
        </Box>
    )
}


function App() {

    const { paths, getCurrentPathObject } = usePaths();
    const { customer_hid } = useUser();

    let currentPathObject = getCurrentPathObject();

    return (
        <Routes>
            <Route path={paths.login.router} element={<Login />} />
            <Route path={paths.updatePwd.router} element={<RequireAuth><UpdatePwd /></RequireAuth>} />
            {/* <Route path="/:customer_hid" element={<Navigate to={paths.home.app({ customer_hid: customer_hid })} />} />
            <Route path="/:customer_hid/app" element={<Navigate to={paths.home.app({ customer_hid: customer_hid })} />} /> */}
            <Route path="/:customer_hid" element={<RequireAuth><AppToolbar /></RequireAuth>} />
            <Route path="/:customer_hid/app" element={<RequireAuth><AppToolbar /></RequireAuth>}>
                <Route index element={<Navigate to={paths.home.app({ customer_hid: customer_hid })} />} replace />
                <Route element={<RoutesAuthorizationChecker accesses={currentPathObject.accesses} fallback={<RouteFallback />}></RoutesAuthorizationChecker>}>
                    <Route path={paths.home.router} element={<Home />} />
                    <Route path={paths.paq.router} element={<Paq />} />
                    <Route path={paths.evalList.router} element={<EvalList />} />
                    <Route path={paths.evalDashboard.router} element={<EvalDashboard />} />
                    <Route path={paths.evalCriterion.router} element={<EvalCriterion />} />
                    <Route path={paths.evalCockpit.router} element={<EvalCockpit />} />
                </Route>
            </Route>
        </Routes >
    );
}

export default App;
