//*********************************************************************************************************************************************//
//                                                               REACT imports                                                                 //
//*********************************************************************************************************************************************//
import { useState, useLayoutEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

//*********************************************************************************************************************************************//
//                                                                 Mui imports                                                                 //
//*********************************************************************************************************************************************//
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
//--------------------------------------------------------------------icons--------------------------------------------------------------------//

//*********************************************************************************************************************************************//
//                                                         external libraries imports                                                          //
//*********************************************************************************************************************************************//
import { Radar } from 'react-chartjs-2';

//*********************************************************************************************************************************************//
//                                                              PROVIDERS imports                                                              //
//*********************************************************************************************************************************************//
import { useUser } from "../../../context/UserProvider";
import { useHttp } from "../../../context/HttpProvider";

//*********************************************************************************************************************************************//
//                                                             COMPONENTS imports                                                              //
//*********************************************************************************************************************************************//


//*********************************************************************************************************************************************//
//                                                               DIVERS imports                                                                //
//*********************************************************************************************************************************************//
import { getServerURL } from "../../../config";



const RadarChart = ({ pc, chapterNumber }) => {

    const { customer_hid, selectedEstablishment, isLoggedIn } = useUser();
    const { httpRequest } = useHttp();
    const graphRef = useRef(null);

    const [isLoading, setIsLoading] = useState(undefined);
    const [graphData, setGraphData] = useState(undefined);

    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const referential_ImID = params.get('referential_ImID');

    useLayoutEffect(() => {
        if (!isLoggedIn || !customer_hid || !selectedEstablishment || !referential_ImID) return;
        const fetchGraphData = async () => {
            setIsLoading(true);
            let response = await httpRequest({
                url: `${getServerURL()}/customer/${customer_hid}/${selectedEstablishment._id}/aev/ref/${referential_ImID}/dashboard/chapterGrades/${chapterNumber}`,
                method: 'get',
                showLoading: false,
                withCredentials: true
            });
            if (response.status === 200) {
                const chapterData = response.data.chapterData;
                // const colors = chapterData.progress?.map(e => ((e?.total > 0 && e?.complete === e?.total) ? thematicCompleteColor : thematicIncompleteColor));
                setGraphData({
                    labels: chapterData.thematics,
                    datasets: [
                        {
                            label: chapterData.chapterName || "",
                            data: chapterData?.grades || [],
                            progress: chapterData?.progress || [],
                            fill: true,
                            backgroundColor: pc.blueFill,
                            borderColor: pc.blueFill,
                            borderWidth: 1,
                            pointStyle: "circle",
                            pointBorderWidth: 1,
                            pointRadius: 8
                        }
                    ]
                });
            }
            setIsLoading(false);
        }
        fetchGraphData();
    }, [customer_hid, selectedEstablishment, referential_ImID, isLoggedIn])


    const tooltipPlugin = {
        tooltip: {
            enabled: true, // Enable tooltips
            callbacks: {
                // Customizing the text shown for each data point
                label: function (context) {
                    let data = context?.dataset?.data || [];
                    let progress = context?.dataset?.progress || [];
                    let currentValue = parseFloat(context.formattedValue.replace(",", "."));
                    let index = data.findIndex(e => e === currentValue);
                    let complete = "";
                    let incomplete = "";
                    let total = "";
                    let percent = "";
                    let label = [];
                    label.push(`Cotations: ${context.formattedValue}`);
                    if (index !== -1) {
                        total = progress[index]?.total;
                        complete = progress[index]?.complete;
                        label.push(`${complete} critère${complete > 1 ? "s" : ""} complet${complete > 1 ? "s" : ""} sur ${total}`);
                        incomplete = progress[index]?.incomplete;
                        label.push(`${incomplete} critère${complete > 1 ? "s" : ""} incomplet${complete > 1 ? "s" : ""} sur ${total}`);
                        percent = progress[index]?.percent;
                        label.push(`Taux de progression: ${percent}%`);
                    }
                    return label;
                },
                // You can also customize the title of the tooltip in a similar way
                title: function (context) {
                    // 'context' is an array of items, so we'll get the first one
                    const label = context[0].label.join(" ");
                    return `${label}`;
                }
            }
        }
    }

    const options = {
        plugins: {
            ...tooltipPlugin,
            legend: {
                display: false
            },
            title: {
                display: true,
                text: `Cotation ${graphData?.datasets?.[0]?.label}`,
                padding: {
                    top: 0,
                    bottom: 10
                },
                font: {
                    size: 18 // You can set the font size here
                },
                color: '#666' // You can set the title color
            },
        },
        scales: {
            r: {  // 'r' is for radial scale in Chart.js 3.x and later
                angleLines: {
                    display: true,  // Hides the angle lines
                },
                suggestedMin: 1,  // Minimum value for the scale
                suggestedMax: 4,  // Maximum value for the scale
                pointLabels: {
                    color: '#333',
                    font: {
                        size: 9,
                        family: 'Arial',

                    },
                    background: "#333"
                },
            }
        },
        responsive: true,
        maintainAspectRatio: true, // Allow the chart to scale in both dimensions
        layout: {
            padding: 0
        },
    };


    return <>
        {
            isLoading &&
            <>
                <Box sx={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}>
                    <CircularProgress />
                </Box>
            </>
        }
        {
            !isLoading && graphData &&
            <Box sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}>
                <Radar ref={graphRef} data={graphData} options={options} />
            </Box>
        }
        {
            !isLoading && !graphData &&
            <Typography>Une erreur est survenue</Typography>
        }
    </>;
};


export { RadarChart }