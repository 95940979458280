
import { Box, Typography, Button } from '@mui/material';
import { colors, getColor } from '../theme/colors';


const ErrorFallback = () => {

    const bgColor = getColor(colors.pink.tint3);

    const handleRetry = () => {
        // Reloads the current route by navigating to the same path
        window.location.href = window.location.pathname;

        // Optional: Reset any local state or boundary context
        // resetErrorBoundary();
    };
    return (
        <Box
            role="alert"
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                backgroundColor: bgColor,
                padding: '20px',
                textAlign: 'center',
            }}
        >
            <Typography variant="h4" gutterBottom color="error">
                Oups ! Une erreur est survenue
            </Typography>
            <Button
                variant="contained"
                color="error"
                onClick={handleRetry}
                sx={{
                    padding: '10px 20px',
                    fontSize: '16px',
                }}
            >
                Réessayer
            </Button>
        </Box>
    );
}


export { ErrorFallback };