//*********************************************************************************************************************************************//
//                                                               REACT imports                                                                 //
//*********************************************************************************************************************************************//
import { useState, useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";

//*********************************************************************************************************************************************//
//                                                                 Mui imports                                                                 //
//*********************************************************************************************************************************************//
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
//--------------------------------------------------------------------icons--------------------------------------------------------------------//

//*********************************************************************************************************************************************//
//                                                         external libraries imports                                                          //
//*********************************************************************************************************************************************//
import { Doughnut } from 'react-chartjs-2';

//*********************************************************************************************************************************************//
//                                                              PROVIDERS imports                                                              //
//*********************************************************************************************************************************************//
import { useUser } from "../../../context/UserProvider";
import { useHttp } from "../../../context/HttpProvider";

//*********************************************************************************************************************************************//
//                                                             COMPONENTS imports                                                              //
//*********************************************************************************************************************************************//


//*********************************************************************************************************************************************//
//                                                               DIVERS imports                                                                //
//*********************************************************************************************************************************************//
import { getServerURL } from "../../../config";

import 'chart.js/auto';

const DoughnutChart = ({ pc, onlyImperatives }) => {

    const { customer_hid, selectedEstablishment, isLoggedIn } = useUser();
    const { httpRequest } = useHttp();

    const [isLoading, setIsLoading] = useState(undefined);
    const [graphData, setGraphData] = useState(undefined);

    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const referential_ImID = params.get('referential_ImID');

    useLayoutEffect(() => {
        if (!isLoggedIn || !customer_hid || !selectedEstablishment || !referential_ImID) return;
        const fetchGraphData = async () => {
            setIsLoading(true);
            let response = await httpRequest({
                url: `${getServerURL()}/customer/${customer_hid}/${selectedEstablishment._id}/aev/ref/${referential_ImID}/dashboard/criteriaStats/${onlyImperatives}`,
                method: 'get',
                showLoading: false,
                withCredentials: true
            });
            if (response.status === 200) {
                const graphDataTmp = response.data.graphData;
                let data = {};
                data.datasets = [
                    {
                        data: graphDataTmp.data,
                        backgroundColor: [
                            pc.greenFill,
                            pc.pinkFill,
                            pc.greyFill
                        ],
                        // borderColor: [
                        //     pc.greenBorder,
                        //     pc.pinkBorder,
                        //     pc.greyBorder
                        // ],
                        borderWidth: 2,
                    }
                ]
                data.labels = graphDataTmp.labels
                data.title = graphDataTmp.title;
                setGraphData(data);
            }
            setIsLoading(false);
        }
        fetchGraphData();
    }, [customer_hid, selectedEstablishment, referential_ImID, isLoggedIn])

    const options = {
        plugins: {
            legend: {
                display: true,
            },
            tooltip: {
                enabled: true, // Disable tooltips
            },
            title: {
                display: true,
                text: `${graphData?.title || ""}`, // Your title text
                padding: {
                    top: 0,
                    bottom: 10
                },
                font: {
                    size: 18 // You can set the font size here
                },
                color: '#666' // You can set the title color
            },
        },
        maintainAspectRatio: false,
    };

    return <>
        {isLoading &&
            <>
                <Box sx={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}>
                    <CircularProgress />
                </Box>
            </>
        }
        {!isLoading && graphData &&
            <Box sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }} >
                <Doughnut data={graphData} options={options} />
            </Box >
        }
        {!isLoading && !graphData &&
            <Typography>Une erreur est survenue</Typography>
        }
    </>;
};



export { DoughnutChart }