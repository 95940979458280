import { colors, getColor } from "../theme/colors";

const fillColor = getColor(colors.pink.tint1);

const SadFace = ({ text }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 48 46" //set viewBox to 0 0 svgXSize svgYSize and svg can be size by parent with CSS
            style={{ display: "block" }} //and set display to block !
        >
            <path
                fill="#EB5A7E"
                d="M23.916.94a12.5 12.5 0 1 0 0 24.999 12.5 12.5 0 0 0 0-25Zm7.032 9.593a1.406 1.406 0 1 1-2.812 0 1.406 1.406 0 0 1 2.812 0Zm-12.149 1.406a1.407 1.407 0 1 1 .517-.098c-.174.069-.36.102-.548.098h.031Zm10.938 5.883a.781.781 0 1 1-1.25.938 5.468 5.468 0 0 0-8.836.101.781.781 0 1 1-1.274-.906 7.03 7.03 0 0 1 11.36-.133Z"
            />
            <rect width={47} height={19} x={0.417} y={26.939} fill={fillColor} rx={9.5} />
            <text
                x="50%" // Position the text in the middle of the SVG
                y="37px" // Position the text in the middle of the SVG
                dominantBaseline="middle" // Vertically center the text
                textAnchor="middle" // Horizontally center the text
                fill="#fff" // Text color
                fontSize="16" // Text size, adjust as needed
            >
                {text}
            </text>
        </svg>
    );
};

export default SadFace;