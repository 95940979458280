//*********************************************************************************************************************************************//
//                                                               REACT imports                                                                 //
//*********************************************************************************************************************************************//
import { useState } from "react";
import { useNavigate } from "react-router-dom";

//*********************************************************************************************************************************************//
//                                                                 Mui imports                                                                 //
//*********************************************************************************************************************************************//
import TextField from '@mui/material/TextField';
import { useTheme } from "@mui/material";
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

//--------------------------------------------------------------------icons--------------------------------------------------------------------//
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

//*********************************************************************************************************************************************//
//                                                         external libraries imports                                                          //
//*********************************************************************************************************************************************//
import { useForm } from "react-hook-form";

//*********************************************************************************************************************************************//
//                                                              PROVIDERS imports                                                              //
//*********************************************************************************************************************************************//
import { useUser } from "../context/UserProvider";
import { useHttp } from "../context/HttpProvider";
import { usePaths } from "../context/PathsProvider";
import { useToast } from "../context/ToastProvider";

//*********************************************************************************************************************************************//
//                                                             COMPONENTS imports                                                              //
//*********************************************************************************************************************************************//
import { StyledAppBar } from "../components/StyledAppBar";
import eqpLogo from "../img/logo_only.png"
import eqpLogoText from "../img/logo_title.png"

//*********************************************************************************************************************************************//
//                                                               DIVERS imports                                                                //
//*********************************************************************************************************************************************//
import { getServerURL } from "../config";
import { colors, getColor } from "../theme/colors";



const Login = () => {
    const { loginUser, customer_hid, selectedEstablishment } = useUser();
    const { httpRequest } = useHttp();
    const { paths } = usePaths();
    const { presentToast } = useToast();
    const [showPassword, setShowPassword] = useState(false);
    const [showResetPasswordDialog, setShowResetPasswordDialog] = useState(false);
    const navigate = useNavigate();
    const theme = useTheme();
    const { register, handleSubmit, formState: { errors } } = useForm();

    const onClosePasswordDialog = () => {
        setShowResetPasswordDialog(false);
    }

    return (
        <>
            <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh', overflowY: "auto" }}>
                <StyledAppBar position="sticky">
                    <Toolbar>
                        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                            Connexion
                        </Typography>
                    </Toolbar>
                </StyledAppBar>
                <Box
                    component="form"
                    sx={{ display: "flex", flexDirection: "column", alignItems: "center", maxHeight: "calc(100vh - 64px)" }}
                    onSubmit={handleSubmit(async (data) => {
                        let response = await httpRequest({
                            url: `${getServerURL()}/customer/${customer_hid}/login`,
                            method: "post",
                            data: { username: data.username, password: btoa(data.password) },
                            headers: { "Content-Type": "application/json" },
                            withCredentials: true
                        })
                        if (response.status === 200) {
                            navigate(paths.home.app({ customer_hid: customer_hid, establishment_id: selectedEstablishment?._id }));
                            await loginUser(customer_hid);
                        } else if (response.status === 401) {
                            presentToast({
                                message: "Accès refusé",
                                severity: "error"
                            })
                        }
                    })}
                >
                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "start", alignItems: "center", margin: "48px", gap: "12px" }}>
                        <img src={eqpLogo} alt="Logo EQP" style={{ height: "25vh", width: "auto" }} />
                        <img src={eqpLogoText} alt="Text Logo EQP" style={{ height: "5vh", width: "auto" }} />
                    </Box>
                    <TextField
                        label="Identifiant"
                        variant="outlined"
                        error={errors.username ? true : false}
                        helperText={errors.username?.message}
                        sx={{ width: "300px", margin: "0 auto 2vh auto" }}
                        {...register("username", { required: "Identifiant requis" })}
                    />
                    <TextField
                        label="Mot de passe"
                        variant="outlined"
                        type={showPassword ? "text" : "password"}
                        error={errors.password ? true : false}
                        helperText={errors.password?.message}
                        sx={{ width: "300px", margin: "0 auto 0.5vh auto" }}
                        {...register("password", { required: "Mot de passe requis" })}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <a
                        href="#"
                        onClick={(event) => {
                            event.preventDefault();
                            setShowResetPasswordDialog(true);
                        }}
                        style={{ marginBottom: "1vh", marginTop: "0.5vh", fontSize: "14px", color: getColor(colors.forest.main) }}
                    >j'ai oublié mon mot de passe</a>
                    <Button
                        variant="contained"
                        sx={{ width: "200px", margin: "0 auto 0 auto" }}
                        type="submit"
                    >
                        Se connecter
                    </Button>
                </Box>
            </Box>
            <Dialog open={showResetPasswordDialog} onClose={onClosePasswordDialog}>
                <DialogTitle>Contactez votre administrateur</DialogTitle>
                <DialogContent>
                    <Typography>
                        Si vous avez oublié votre mot de passe, votre administrateur peut vous aider. Veuillez le contacter.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClosePasswordDialog} variant="contained">OK</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default Login;