import { immerable } from "immer";
import { removeAccents, highlightText } from "../lib/lib";


function Observation(o) {
    this.question = o?.question;
    this.rbpp = o?.rbpp;
    this.requested_response = o?.requested_response;
    this.nb_responses = o?.nb_responses;
    this.concerned_activities = o?.concerned_activities;
    this.is_archived = o?.is_archived || false;
    this.immutable_id = o?.immutable_id;
    this.grade = o?.grade;
    this.progress = o?.progress;
    this.isOpen = true;
    this.hidden = false;
    this[immerable] = true;
}

Observation.prototype.expand = function () {
    this.isOpen = !this.isOpen;
}


function Criterion(c) {
    this.name = c?.name;
    this.backupName = c?.name;
    this.job_groups = c?.job_groups;
    this.important = c?.important;
    this.is_archived = c?.is_archived || false;
    this.immutable_id = c?.immutable_id;
    this.grade = c?.grade;
    this.progress = c?.progress;
    this.isEmptyParent = c?.isEmptyParent;
    this.isApproved = c?.isApproved;
    this.observations = [];
    this.isOpen = true;
    this.hidden = false;
    if (c?.observations?.length > 0) {
        for (let i = 0; i < c.observations.length; i++) {
            this.observations[i] = new Observation(c.observations[i]);
        }
    }
    this[immerable] = true;
}

Criterion.prototype.expand = function () {
    this.isOpen = !this.isOpen;
}


function Objective(o) {
    this.name = o?.name;
    this.backupName = o?.name;
    this.thematic = o?.thematic;
    this.is_archived = o?.is_archived || false;
    this.immutable_id = o?.immutable_id;
    this.grade = o?.grade;
    this.progress = o?.progress;
    this.isApproved = o?.isApproved;
    this.criteria = [];
    this.isOpen = true;
    this.hidden = false;
    if (o?.criteria?.length > 0) {
        for (let i = 0; i < o.criteria.length; i++) {
            this.criteria[i] = new Criterion(o.criteria[i]);
        }
    }
    this[immerable] = true;
}

Objective.prototype.expand = function () {
    this.isOpen = !this.isOpen;
}


function Chapter(c) {
    this.name = c?.name;
    this.backupName = c?.name;
    this.is_archived = c?.is_archived || false; //archive elements instead of delete
    this.immutable_id = c?.immutable_id;
    this.objectives = [];
    this.grade = c?.grade;
    this.progress = c?.progress;
    this.isApproved = c?.isApproved;
    this.isOpen = true; //true to see nested children
    this.hidden = false; //if filtered for example
    if (c?.objectives?.length > 0) {
        for (let i = 0; i < c.objectives.length; i++) {
            this.objectives[i] = new Objective(c.objectives[i], this);
        }
    }
    this[immerable] = true;
}

Chapter.prototype.expand = function () {
    this.isOpen = !this.isOpen;
}


function Referential(r) {
    this.immutable_id = r?.immutable_id;
    this.is_released = {
        value: r?.is_released?.value,
        by: r?.is_released?.by,
        on: r?.is_released?.on
    }
    this.is_archived = {
        value: r?.is_archived?.value,
        by: r?.is_archived?.by,
        on: r?.is_archived?.on
    }
    this.created = {
        by: r?.created?.by,
        on: r?.created?.on
    }
    this.version = r?.version;
    this.HAS_date = r?.HAS_date;
    this._id = r?._id;
    this.expandAll = true;
    this.showArchived = false;
    this.chapters = [];
    if (r?.chapters?.length > 0) {
        for (let i = 0; i < r.chapters.length; i++) {
            this.chapters[i] = new Chapter(r.chapters[i]);
        }
    }
    this[immerable] = true;
    //create a backup so we can check if referential has been modified by user
}



Referential.prototype.filter = function (searchFilter, filterCI) {
    const isSearchFilter = searchFilter !== "";
    const searchTerms = removeAccents(searchFilter?.toLowerCase())?.split(" ");

    const termMatches = (text) => {
        const cleanedText = removeAccents(text.toLowerCase());
        return searchTerms.every(term => cleanedText.includes(term));
    };

    for (let [chapterIndex, chapter] of Object.entries(this.chapters)) {
        let chapterName = `Chapitre ${Number(chapterIndex) + 1}) ${chapter.name}`;

        for (let [objectiveIndex, objective] of Object.entries(chapter.objectives)) {
            let objectiveName = `Objectif ${Number(chapterIndex) + 1}.${Number(objectiveIndex) + 1}) ${objective.name}`;

            let criteriaMatch = false;
            for (let [criterionIndex, criterion] of Object.entries(objective.criteria)) {
                let criterionName = `Critère ${Number(chapterIndex) + 1}.${Number(objectiveIndex) + 1}.${Number(criterionIndex) + 1}) ${criterion.name}`;
                let hiddenCI = (filterCI && !criterion.important);
                let hiddenSearch = (isSearchFilter && !termMatches(criterionName));
                criterion.hidden = (filterCI && !criterion.important) || (isSearchFilter && !termMatches(criterionName))
                criteriaMatch = criteriaMatch || !criterion.hidden;
            }

            objective.hidden = criteriaMatch ? false : filterCI ? true : (isSearchFilter && !termMatches(objectiveName));
        }

        let objectivesMatch = chapter.objectives.some(o => !o.hidden);
        chapter.hidden = objectivesMatch ? false : filterCI ? true : (isSearchFilter && !termMatches(chapterName));
    }
}


Referential.prototype.showArchivedElements = function () {
    //shows or hide archived elements depending on showArchived value
    this.showArchived = !this.showArchived;
}

Referential.prototype.expand = function () {
    //expand or collapse referential depending on expandAll value
    this.expandAll = !this.expandAll;
    for (let chapter of this.chapters) {
        chapter.isOpen = this.expandAll;
        for (let objective of chapter.objectives) {
            objective.isOpen = this.expandAll;
            for (let criterion of objective.criteria) {
                criterion.isOpen = this.expandAll;
                for (let observation of criterion.observations) {
                    observation.isOpen = this.expandAll;
                }
            }
        }
    }
}

export { Referential };