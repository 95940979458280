//*********************************************************************************************************************************************//
//                                                               REACT imports                                                                 //
//*********************************************************************************************************************************************//
import { useRef, useMemo } from "react";

//*********************************************************************************************************************************************//
//                                                                 Mui imports                                                                 //
//*********************************************************************************************************************************************//
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from "@mui/material/FormGroup";
//--------------------------------------------------------------------icons--------------------------------------------------------------------//
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import CountIcon from "../../../icons/CountIcon";

//*********************************************************************************************************************************************//
//                                                         external libraries imports                                                          //
//*********************************************************************************************************************************************//
import { Controller, useFormContext, useWatch, useFieldArray } from "react-hook-form";

//*********************************************************************************************************************************************//
//                                                              PROVIDERS imports                                                              //
//*********************************************************************************************************************************************//
import { useUser } from "../../../context/UserProvider";

//*********************************************************************************************************************************************//
//                                                             COMPONENTS imports                                                              //
//*********************************************************************************************************************************************//
import { RichTextEditor, getEmptyDelta, isEmptyDelta } from "../../../components/RichTextEditor/RichTextEditor";
import { AuthorizationChecker } from "../../../components/AuthorizationChecker";

//*********************************************************************************************************************************************//
//                                                             CUSTOM HOOKS imports                                                            //
//*********************************************************************************************************************************************//


//*********************************************************************************************************************************************//
//                                                               DIVERS imports                                                                //
//*********************************************************************************************************************************************//

import { Accordion, AccordionDetails, AccordionSummary, Chip, FormControl, FormControlLabel, FormLabel, Input, Radio, RadioGroup, Slider, TextareaAutosize, Tooltip } from "@mui/material";
import CheckedLocked from "../../../img/CheckedLocked";
import { colors, getColor } from "../../../theme/colors";





const DrawerRenderer = ({ isCriterionApproved }) => {

    const { control, setValue } = useFormContext();
    const { lists, isAuthorized } = useUser();

    //hook to handle dynamic fields for strengths
    const strengthsFieldsManager = useFieldArray({
        name: "strengths",
        control
    });

    //hook to handle dynamic fields for weaknesses
    const weaknessesFieldsManager = useFieldArray({
        name: "weaknesses",
        control
    });

    const theme = useTheme();

    const groupMargin = theme.spacing(4);
    const elementMargin = theme.spacing(1);

    const componentColors = useRef({
        mainColor: getColor(colors.forest.main),
        mainColordisabled: getColor(colors.forest.main, 0.5),
        lockColor: getColor(colors.pink.tint1),
        backgroundColor: getColor(colors.greenLemon.main, 0.05)
    });
    const cc = { ...componentColors.current };

    const isReaderOnly = !isAuthorized({ module: lists?.LICENSE_MODULE_VALUES["AEV"], baseAction: lists?.BASE_ACTION_VALUES["WRT"] });

    //useWatch on strengths and weaknesses to track number on each for the accordion title
    let RhfStrengths = useWatch({
        control,
        name: "strengths",
    });

    let RhfWeaknesses = useWatch({
        control,
        name: "weaknesses",
    });

    const getNbNonEmptyStrengthsElements = useMemo(() => {
        if (!RhfStrengths) return 0;
        let nb = 0;
        for (let strength of RhfStrengths) {
            if (!isEmptyDelta(strength?.delta)) {
                nb++;
            }
        }
        return nb;
    }, [RhfStrengths])

    const getNbNonEmptyWeaknessesElements = useMemo(() => {
        if (!RhfWeaknesses) return 0;
        let nb = 0;
        for (let weakness of RhfWeaknesses) {
            if (!isEmptyDelta(weakness?.delta)) {
                nb++;
            }
        }
        return nb;
    }, [RhfWeaknesses])

    let RhfSynthesisStatus = useWatch({
        control,
        name: "synthesisStatus",
    });

    let isSynthesisVerified = () => {
        return RhfSynthesisStatus.value === lists?.FIELD_STATUSES["VERIFIED"];
    }

    let RhfStrengthsStatus = useWatch({
        control,
        name: "strengthsStatus",
    });

    let isStrengthsVerified = () => {
        return RhfStrengthsStatus.value === lists?.FIELD_STATUSES["VERIFIED"];
    }

    let RhfWeaknessesStatus = useWatch({
        control,
        name: "weaknessesStatus",
    });

    let isWeaknessesVerified = () => {
        return RhfWeaknessesStatus.value === lists?.FIELD_STATUSES["VERIFIED"];
    }

    return (
        <>
            {/************************************* SYNTHSESIS **************************************/}
            <Accordion
                defaultExpanded
                sx={{
                    marginBottom: groupMargin,
                    background: cc.backgroundColor
                }}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon style={{ color: cc.mainColor }} />}
                    sx={{
                        position: 'sticky',
                        top: 0,
                        zIndex: 1,
                        background: 'rgb(244, 247, 234)',
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center"
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "100%"
                        }}
                    >
                        <Chip
                            label="Synthèse"
                            color="primary"
                            sx={{ fontSize: "20px" }} />
                        {isSynthesisVerified() &&
                            <Tooltip title="Synthèse vérifiée">
                                <Box style={{ width: "32px", height: "32px" }}>
                                    < CheckedLocked
                                        checkColor={cc.mainColor}
                                        lockColor={cc.lockColor}
                                    />
                                </Box>
                            </Tooltip>
                        }
                    </Box>
                </AccordionSummary>
                <AccordionDetails>
                    <Controller
                        name={`synthesis`}
                        rules={{}}
                        control={control}
                        render={({ field }) => {
                            const { onChange, value } = field;
                            const disabledVerified = isSynthesisVerified();
                            let disabled = isReaderOnly || disabledVerified;
                            return (
                                <RichTextEditor
                                    value={(value?.delta && !isEmptyDelta(value.delta)) ? value.delta : value.html}
                                    onChange={({ html, delta }) => {
                                        onChange({ html: html, delta: delta });
                                    }}
                                    style={{ marginBottom: "8px" }}
                                    disabled={disabled}
                                    showLockedIcon={disabled}
                                    lockedIconTooltip={isReaderOnly ? "Votre accès est limité à la lecture" : "Synthèse vérifiée"}
                                    lockedIconColor={cc.lockColor}
                                />
                            )
                        }}
                    />
                    <AuthorizationChecker
                        module={lists?.LICENSE_MODULE_VALUES["AEV"]}
                        baseAction={lists?.BASE_ACTION_VALUES["VRF"]}
                    >
                        <Controller
                            name={`synthesisStatus.value`}
                            control={control}
                            render={({ field }) => {
                                return (
                                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                        <FormGroup>
                                            <Tooltip title={isCriterionApproved ? "Le critère est approuvé" : ""}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={field?.value === lists?.FIELD_STATUSES["VERIFIED"]}
                                                            onChange={(event) => event.target.checked ? field.onChange(lists?.FIELD_STATUSES["VERIFIED"]) : field.onChange(lists?.FIELD_STATUSES["DRAFT"])}
                                                            sx={{
                                                                color: cc.mainColor,
                                                                '&.Mui-disabled': {
                                                                    color: cc.mainColordisabled,
                                                                },
                                                            }}
                                                        />
                                                    }
                                                    label={<Typography>{field.value === lists?.FIELD_STATUSES["DRAFT"] ? "Vérifier" : "Vérifié"}</Typography>}
                                                    disabled={isCriterionApproved}
                                                    sx={{
                                                        color: cc.mainColor,
                                                        '&.Mui-disabled': {
                                                            color: cc.mainColordisabled,
                                                        }
                                                    }}
                                                />
                                            </Tooltip>
                                        </FormGroup>
                                    </Box>
                                )
                            }}
                        />
                    </AuthorizationChecker>
                </AccordionDetails>
            </Accordion >
            {/************************************* STRENGTHS **************************************/}
            < Accordion
                defaultExpanded
                sx={{
                    background: cc.backgroundColor,
                    marginBottom: groupMargin
                }
                }
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon style={{ color: cc.mainColor }} />}
                    sx={{
                        position: 'sticky',
                        top: 0,
                        zIndex: 1,
                        background: 'rgb(244, 247, 234)'
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "100%"
                        }}
                    >
                        <Chip
                            label={<div style={{ display: "flex", justifyContent: "start", alignItems: "center", gap: "8px" }}><div>Points forts</div><CountIcon number={getNbNonEmptyStrengthsElements} /></div>}
                            color="primary"
                            sx={{
                                fontSize: "20px",
                                display: "flex",
                                justifyContent: "start",
                            }} />
                        {isStrengthsVerified() &&
                            <Tooltip title="Points forts vérifiés">
                                <Box style={{ width: "32px", height: "32px" }}>
                                    < CheckedLocked
                                        checkColor={cc.mainColor}
                                        lockColor={cc.lockColor}
                                    />
                                </Box>
                            </Tooltip>
                        }
                    </Box>
                </AccordionSummary>
                <AccordionDetails>
                    <Box sx={{ display: "flex", flexDirection: "column", gap: elementMargin, marginBottom: elementMargin }}>
                        {strengthsFieldsManager?.fields?.map((field, index) => {
                            return (
                                <Controller
                                    key={field.id}
                                    name={`strengths[${index}]`}
                                    rules={{}}
                                    control={control}
                                    render={({ field: ctrlField }) => {
                                        const disabledVerified = isStrengthsVerified();
                                        const disabled = isReaderOnly || disabledVerified;
                                        return (
                                            <Box sx={{ display: "flex", justifyContent: "start", alignItems: "center" }}>
                                                <RichTextEditor
                                                    value={(ctrlField.value?.delta && !isEmptyDelta(ctrlField.value.delta)) ? ctrlField.value.delta : ctrlField.value.html}
                                                    onChange={({ delta, html }) => {
                                                        // let deltaValue = editor.getContents();
                                                        ctrlField.onChange({ html: html, delta: delta });;
                                                    }}
                                                    disabled={disabled}
                                                    showLockedIcon={disabled}
                                                    lockedIconTooltip={isReaderOnly ? "Votre accès est limité à la lecture" : "Points forts vérifiés"}
                                                    lockedIconColor={cc.lockColor}
                                                />
                                                <AuthorizationChecker
                                                    module={lists?.LICENSE_MODULE_VALUES["AEV"]}
                                                    baseAction={lists?.BASE_ACTION_VALUES["WRT"]}
                                                >
                                                    <IconButton
                                                        onClick={() => {
                                                            if (strengthsFieldsManager?.fields?.length > 1) {
                                                                strengthsFieldsManager.remove(index);
                                                            } else {
                                                                setValue(`strengths[${index}]`, { html: "", delta: getEmptyDelta() });
                                                            }
                                                        }}
                                                        disabled={isStrengthsVerified()}
                                                    >
                                                        <RemoveCircleIcon />
                                                    </IconButton>
                                                </AuthorizationChecker>
                                            </Box>
                                        )
                                    }}
                                />)
                        })}
                        {strengthsFieldsManager?.fields?.length < 3 &&
                            <AuthorizationChecker
                                module={lists?.LICENSE_MODULE_VALUES["AEV"]}
                                baseAction={lists?.BASE_ACTION_VALUES["WRT"]}
                            >
                                <IconButton
                                    onClick={() => {
                                        strengthsFieldsManager?.append({ html: "", delta: getEmptyDelta() });
                                    }}
                                    disabled={isStrengthsVerified()}
                                >
                                    <AddCircleIcon />
                                </IconButton>
                            </AuthorizationChecker>
                        }
                    </Box>
                    <AuthorizationChecker
                        module={lists?.LICENSE_MODULE_VALUES["AEV"]}
                        baseAction={lists?.BASE_ACTION_VALUES["VRF"]}
                    >
                        <Controller
                            name={`strengthsStatus.value`}
                            control={control}
                            render={({ field }) => {
                                return (
                                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                        <FormGroup>
                                            <Tooltip title={isCriterionApproved ? "Le critère est approuvé" : ""}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={field?.value === lists?.FIELD_STATUSES["VERIFIED"]}
                                                            onChange={(event) => event.target.checked ? field.onChange(lists?.FIELD_STATUSES["VERIFIED"]) : field.onChange(lists?.FIELD_STATUSES["DRAFT"])}
                                                            sx={{
                                                                color: cc.mainColor,
                                                                '&.Mui-disabled': {
                                                                    color: cc.mainColordisabled,
                                                                },
                                                            }}
                                                        />
                                                    }
                                                    label={<Typography>{field.value === lists?.FIELD_STATUSES["DRAFT"] ? "Vérifier" : "Vérifié"}</Typography>}
                                                    disabled={isCriterionApproved}
                                                    sx={{
                                                        color: cc.mainColor,
                                                        '&.Mui-disabled': {
                                                            color: cc.mainColordisabled,
                                                        },
                                                    }}
                                                />
                                            </Tooltip>
                                        </FormGroup>
                                    </Box>
                                )
                            }}
                        />
                    </AuthorizationChecker>
                </AccordionDetails>
            </Accordion >
            {/************************************* WEAKNESSES **************************************/}
            < Accordion
                defaultExpanded
                sx={{
                    background: cc.backgroundColor,
                    marginBottom: groupMargin
                }}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon style={{ color: cc.mainColor }} />}
                    sx={{
                        position: 'sticky',
                        top: 0,
                        zIndex: 1,
                        background: 'rgb(244, 247, 234)'
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "100%"
                        }}
                    >
                        <Chip
                            label={<div style={{ display: "flex", justifyContent: "start", alignItems: "center", gap: "8px" }}><div>Points d'efforts</div><CountIcon number={getNbNonEmptyWeaknessesElements} /></div>}
                            color="primary"
                            sx={{
                                fontSize: "20px",
                                display: "flex",
                                justifyContent: "start",
                            }} />
                        {isWeaknessesVerified() &&
                            <Tooltip title="Points d'efforts vérifiés">
                                <Box style={{ width: "32px", height: "32px" }}>
                                    < CheckedLocked
                                        checkColor={cc.mainColor}
                                        lockColor={cc.lockColor}
                                    />
                                </Box>
                            </Tooltip>
                        }
                    </Box>
                </AccordionSummary>
                <AccordionDetails>
                    <Box sx={{ display: "flex", flexDirection: "column", gap: elementMargin }}>
                        {weaknessesFieldsManager?.fields?.map((field, index) => {
                            return (
                                <Controller
                                    key={field.id}
                                    name={`weaknesses[${index}]`}
                                    rules={{}}
                                    control={control}
                                    render={({ field: ctrlField }) => {
                                        const disabledVerified = isWeaknessesVerified();
                                        const disabled = isReaderOnly || disabledVerified;
                                        return (
                                            <Box sx={{ display: "flex", justifyContent: "start", alignItems: "center" }}>
                                                <Box sx={{ flexGrow: 1, display: "block" }}>
                                                    <RichTextEditor
                                                        value={(ctrlField.value?.delta && !isEmptyDelta(ctrlField.value.delta)) ? ctrlField.value.delta : ctrlField.value.html}
                                                        onChange={({ delta, html }) => {
                                                            // let deltaValue = editor.getContents();
                                                            ctrlField.onChange({ html: html, delta: delta });;
                                                        }}
                                                        disabled={disabled}
                                                        showLockedIcon={disabled}
                                                        lockedIconTooltip={isReaderOnly ? "Votre accès est limité à la lecture" : "Points d'efforts vérifiés"}
                                                        lockedIconColor={cc.lockColor}
                                                    />
                                                </Box>
                                                <AuthorizationChecker
                                                    module={lists?.LICENSE_MODULE_VALUES["AEV"]}
                                                    baseAction={lists?.BASE_ACTION_VALUES["WRT"]}
                                                >
                                                    <IconButton
                                                        onClick={() => {
                                                            if (weaknessesFieldsManager?.fields?.length > 1) {
                                                                weaknessesFieldsManager.remove(index);
                                                            } else {
                                                                setValue(`weaknesses[${index}]`, { html: "", delta: getEmptyDelta() });
                                                            }
                                                        }}
                                                        disabled={isWeaknessesVerified()}
                                                    >
                                                        <RemoveCircleIcon />
                                                    </IconButton>
                                                </AuthorizationChecker>
                                            </Box>
                                        )
                                    }}
                                />
                            )
                        })}
                        {weaknessesFieldsManager?.fields?.length < 3 &&
                            <AuthorizationChecker
                                module={lists?.LICENSE_MODULE_VALUES["AEV"]}
                                baseAction={lists?.BASE_ACTION_VALUES["WRT"]}
                            >
                                <IconButton
                                    onClick={() => {
                                        weaknessesFieldsManager?.append({ html: "", delta: getEmptyDelta() });
                                    }}
                                    disabled={isWeaknessesVerified()}
                                >
                                    <AddCircleIcon />
                                </IconButton>
                            </AuthorizationChecker>
                        }
                    </Box>
                    <AuthorizationChecker
                        module={lists?.LICENSE_MODULE_VALUES["AEV"]}
                        baseAction={lists?.BASE_ACTION_VALUES["VRF"]}
                    >
                        <Controller
                            name={`weaknessesStatus.value`}
                            control={control}
                            render={({ field }) => {
                                return (
                                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                        <FormGroup>
                                            <Tooltip title={isCriterionApproved ? "Le critère est approuvé" : ""}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={field?.value === lists?.FIELD_STATUSES["VERIFIED"]}
                                                            onChange={(event) => event.target.checked ? field.onChange(lists?.FIELD_STATUSES["VERIFIED"]) : field.onChange(lists?.FIELD_STATUSES["DRAFT"])}
                                                            sx={{
                                                                color: cc.mainColor,
                                                                '&.Mui-disabled': {
                                                                    color: cc.mainColordisabled,
                                                                },
                                                            }}
                                                        />
                                                    }
                                                    label={<Typography>{field.value === lists?.FIELD_STATUSES["DRAFT"] ? "Vérifier" : "Vérifié"}</Typography>}
                                                    disabled={isCriterionApproved}
                                                    sx={{
                                                        color: cc.mainColor,
                                                        '&.Mui-disabled': {
                                                            color: cc.mainColordisabled,
                                                        },
                                                    }}
                                                />
                                            </Tooltip>
                                        </FormGroup>
                                    </Box>
                                )
                            }}
                        />
                    </AuthorizationChecker>
                </AccordionDetails>
            </Accordion >
        </>
    );
}

export { DrawerRenderer }