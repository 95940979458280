import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import valerienceImageFile from '../img/valerience.png';
import eqpImageFile from '../img/eqp.png';

async function getImageBase64(file) {
    const response = await fetch(file);
    const blob = await response.blob();
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
    });
}

class GridHelper {


    /**
     * 
     * @param {*} dataGrid 
     * @returns 
     */
    async exportTableToExcel(dataGrid) {
        // We use all the visible/fetched rows (filter applied + sorting as well)
        const dataGridRows = dataGrid.getPrePaginationRowModel().rows;
        if (dataGridRows.length === 0) {
            return;
        }

        // Create a new workbook and worksheet
        const workbook = new ExcelJS.Workbook();

        const worksheet = workbook.addWorksheet('Feuille 1', { pageSetup: { horizontalCentered: true } });

        // Preparing data by building an array of arrays
        // We only take visible columns
        const visibleColumns = dataGrid.getVisibleLeafColumns().filter(vc => !vc.id.startsWith('mrt'));

        // Header labels are taken from column header text
        const visibleColumnHeaders = visibleColumns.map(vc => vc.columnDef.header);

        // Add empty rows until row 10
        for (let i = 1; i < 10; i++) {
            worksheet.addRow([]);
        }

        // Add header row
        const headerRow = worksheet.addRow(visibleColumnHeaders);

        // Apply styles to header row
        headerRow.eachCell((cell) => {
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FF127D78' } // Background color
            };
            cell.font = {
                color: { argb: 'FFFFFFFF' }, // Text color
                bold: true
            };

        });

        // Freeze the header row
        worksheet.views = [{ state: 'frozen', ySplit: 10 }];
        let nb_imperative_criteria = 0;
        // Add data rows
        dataGridRows.forEach(row => {
            const rowValues = [];

            if (row.original.imperative_criterion) {
                nb_imperative_criteria++;
            }
            visibleColumns.forEach(column => {
                const cell = row.getAllCells().find(c => c.id.endsWith(column.id));
                rowValues.push(cell.getValue());
            });
            worksheet.addRow(rowValues);
        });


        // Apply widths to columns
        const horizontally_centered = ['priority', 'created', 'start_date', 'due_date', 'completion_date', 'delay'];
        visibleColumns.forEach((column, index) => {
            const col = worksheet.getColumn(index + 1);
            if (index === 0) {
                col.width = 10;
                col.alignment = { horizontal: 'center', vertical: 'middle' };
            } else {
                col.width = Math.floor(column.getSize() / 8); // convert pixels to approximate Excel width units
                col.alignment = { wrapText: true, vertical: 'middle' };
                if (horizontally_centered.includes(column.id)) {
                    col.alignment.horizontal = 'center';
                }
            }
        })

        //Apply border to cells
        for (let row = 10; row <= dataGridRows.length + 10; row++) {
            for (let col = 1; col <= visibleColumns.length; col++) {
                const cell = worksheet.getCell(row, col);
                cell.border = {
                    top: { style: 'thin', color: { argb: 'FF127D78' } },
                    left: { style: 'thin', color: { argb: 'FF127D78' } },
                    bottom: { style: 'thin', color: { argb: 'FF127D78' } },
                    right: { style: 'thin', color: { argb: 'FF127D78' } }
                };
            }
        }

        //Header row cells should be all horizontally centered
        headerRow.eachCell((cell) => {
            cell.alignment = { horizontal: 'center' };
        });


        // Merge and center cells C3:E5 and apply text style
        const date = new Date();
        const options = { day: 'numeric', month: 'long', year: 'numeric' };
        const formattedDate = new Intl.DateTimeFormat('fr-FR', options).format(date);
        worksheet.mergeCells('C3:G5');
        const mergedCell = worksheet.getCell('C3');
        mergedCell.value = `PLAN D'AMELIORATION DE LA QUALITE ET DE GESTION DES RISQUES \n date d'export: ${formattedDate}`;
        mergedCell.alignment = {
            vertical: 'middle',
            horizontal: 'center',
            wrapText: true
        };

        mergedCell.font = {
            color: { argb: 'FF127D78' }, // Text color
            bold: true,
            size: 14
        };

        const valerienceBase64Image = await getImageBase64(valerienceImageFile);

        const imageId1 = workbook.addImage({
            base64: valerienceBase64Image,
            extension: 'png',
        });
        //worksheet.addImage(imageId1, 'A1:B9');
        worksheet.addImage(imageId1, {
            tl: { col: 8, row: 0 },
            ext: { width: 180, height: 170.75 }
        });

        const eqpBase64Image = await getImageBase64(eqpImageFile);
        const imageId2 = workbook.addImage({
            base64: eqpBase64Image,
            extension: 'png',
        });
        //worksheet.addImage(imageId1, 'A1:B9');
        worksheet.addImage(imageId2, {
            tl: { col: 0, row: 0 },
            ext: { width: 150, height: 171.33 }
        });


        // Save the file
        // Create a buffer from the workbook
        const buffer = await workbook.xlsx.writeBuffer();

        // Create a Blob from the buffer and save it using FileSaver
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        //const date = new Date();
        const fileName = `paq_export_${date.toLocaleDateString().replace(/\//g, '-')}_${date.toLocaleTimeString().replace(/:/g, '-')}.xlsx`;
        saveAs(blob, fileName);

    }

}
const instance = new GridHelper();
export default instance;