//*********************************************************************************************************************************************//
//                                                               REACT imports                                                                 //
//*********************************************************************************************************************************************//
import { useState, useContext, } from "react";

//*********************************************************************************************************************************************//
//                                                                 Mui imports                                                                 //
//*********************************************************************************************************************************************//
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
//--------------------------------------------------------------------icons--------------------------------------------------------------------//

//*********************************************************************************************************************************************//
//                                                         external libraries imports                                                          //
//*********************************************************************************************************************************************//
import { Chart as ChartJS, registerables } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';


//*********************************************************************************************************************************************//
//                                                              PROVIDERS imports                                                              //
//*********************************************************************************************************************************************//


//*********************************************************************************************************************************************//
//                                                             COMPONENTS imports                                                              //
//*********************************************************************************************************************************************//
import { RadarChart } from "./RadarChart";
import { BarChartProgress } from "./BarChartProgress";
import { DoughnutChart } from "./DoughnutChart";
import { RefData } from "./RefData";
import { QuestEvaluatifData } from "./QuestEvaluatifData";
import { EvalProgressRate } from "./EvalProgressRate";
import { EvalActionButtons } from "./EvalActionButtons";
import { BarChartWeakStr } from "./BarChartWeakStr";
import { BarChartAevActions } from "./BarChartAevActions";
import { colors, getColor } from "../../../theme/colors";

//*********************************************************************************************************************************************//
//                                                               DIVERS imports                                                                //
//*********************************************************************************************************************************************//


//padding is page padding, space is space between elements.
//maxHeight is maximum height of children elements
const ResponsiveContainer = ({ gap = 0, maxHeight = undefined, aspectRatio = "100%", children }) => {
    // let aspectRatioVal = aspectRatio.split("%")?.[0] || 0;
    return (
        <Box sx={{
            position: 'relative',
            width: '100%',
            maxHeight: `calc(${maxHeight} - ${gap}px)`, //respects aspectRatio until maxHeight is reached. Used to allow a width increase of the component without increasing the height.
            '&::before': {
                content: '""',
                display: 'block',
                paddingTop: `calc(${aspectRatio} - ${gap}px)` // aspectRatio is controlled with this property. 100% is 1, 50% is 0.5 etc...
            },
            '& > .children-content': {
                position: 'absolute',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }
        }}>
            <div className="children-content">
                {children}
            </div>
        </Box>
    )
};


const EvalDashboard = () => {

    const theme = useTheme();

    const margin = theme.spacing(2); //stored ad "16px"
    const marginVal = parseInt(theme.spacing(2), 10); //stored as 16
    const innerPadding = theme.spacing(2); //stored ad "16px"
    const innerPaddingVal = parseInt(theme.spacing(2), 10); //stored as 16

    const [pc, setPc] = useState({
        // pinkBorder: getColor(colors.pink.tint1),
        pinkFill: getColor(colors.pink.tint1, 0.6),
        // blueBorder: getColor(colors.azure.main, 0.6),
        blueFill: getColor(colors.azure.main, 0.6),
        // greenBorder: getColor(colors.greenLemon.main, 0.8),
        greenFill: getColor(colors.greenLemon.main, 0.6),
        // orangeBorder: getColor(colors.orange.main, 0.8),
        // orangeFill: getColor(colors.orange.main, 0.2),
        // greyBorder: getColor(colors.grey.text, 0.8),
        greyFill: getColor(colors.grey.text, 0.3)
    });


    //register chartJS elements
    ChartJS.register(
        ...registerables,
        annotationPlugin,
    );


    return (
        <>
            <Box
                sx={{
                    width: '100%',
                    maxHeight: `100%`,
                    background: '#f7f7f7',
                    overflowY: "auto",
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                <Box sx={{
                    maxWidth: "1536px",
                    width: "100%",
                    padding: innerPadding,
                    '&::after': { // Trick to have padding inside scroll area of the container
                        content: '""',
                        display: 'block',
                        height: innerPadding,
                        width: '100%'
                    }
                }}>
                    <Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "start", gap: margin }} >
                        <Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "center", gap: margin }} >
                            <Box sx={{ width: { xs: `100%`, sm: `calc(600px - ${innerPadding})` } }} >
                                <Box sx={{ display: "flex", width: "100%", height: "auto", gap: innerPadding, flexWrap: "wrap" }}>
                                    <Paper elevation={3} sx={{ padding: innerPadding, display: "flex", justifyContent: "center", width: `calc(50% - ${innerPaddingVal / 2}px)` }}>
                                        <ResponsiveContainer aspectRatio={"50%"} gap={innerPaddingVal}>
                                            <RefData />
                                        </ResponsiveContainer>
                                    </Paper>
                                    <Paper elevation={3} sx={{ padding: innerPadding, display: "flex", justifyContent: "center", width: `calc(50% - ${innerPaddingVal / 2}px)` }}>
                                        <ResponsiveContainer aspectRatio={"50%"} gap={innerPaddingVal}>
                                            <QuestEvaluatifData />
                                        </ResponsiveContainer>
                                    </Paper>
                                </Box>
                            </Box>
                            <Box sx={{ width: { xs: `100%`, sm: `calc(600px - ${innerPadding})` } }} >
                                <Box sx={{ display: "flex", width: "100%", height: "auto", gap: innerPadding, flexWrap: "wrap" }}>
                                    <Paper elevation={3} sx={{ padding: innerPadding, display: "flex", justifyContent: "center", width: `calc(50% - ${innerPaddingVal / 2}px)` }}>
                                        <ResponsiveContainer aspectRatio={"50%"} gap={innerPaddingVal}>
                                            <EvalProgressRate />
                                        </ResponsiveContainer>
                                    </Paper>
                                    <Paper elevation={3} sx={{ padding: innerPadding, display: "flex", justifyContent: "center", width: `calc(50% - ${innerPaddingVal / 2}px)` }}>
                                        <ResponsiveContainer aspectRatio={"50%"} gap={innerPaddingVal}>
                                            <EvalActionButtons />
                                        </ResponsiveContainer>
                                    </Paper>
                                </Box>
                            </Box>
                            <Box sx={{ width: { xs: `100%`, sm: `calc(600px - ${innerPadding})` } }} >
                                <Box sx={{ display: "flex", width: "100%", height: "auto", gap: innerPadding, flexWrap: "wrap" }}>
                                    <Paper elevation={3} sx={{ padding: innerPadding, display: "flex", justifyContent: "center", width: `100%` }}>
                                        <ResponsiveContainer aspectRatio={"50%"} gap={innerPaddingVal}>
                                            <BarChartWeakStr pc={pc} />
                                        </ResponsiveContainer>
                                    </Paper>
                                </Box>
                            </Box>
                            <Box sx={{ width: { xs: `100%`, sm: `calc(600px - ${innerPadding})` } }} >
                                <Box sx={{ display: "flex", width: "100%", height: "auto", gap: innerPadding, flexWrap: "wrap" }}>
                                    <Paper elevation={3} sx={{ padding: innerPadding, display: "flex", justifyContent: "center", width: `100%` }}>
                                        <ResponsiveContainer aspectRatio={"50%"} gap={innerPaddingVal}>
                                            <BarChartAevActions pc={pc} />
                                        </ResponsiveContainer>
                                    </Paper>
                                </Box>
                            </Box>
                            <Box sx={{ width: { xs: `100%`, sm: `calc(600px - ${innerPadding})` } }} >
                                <Box sx={{ display: "flex", width: "100%", height: "auto", gap: innerPadding, flexWrap: "wrap" }}>
                                    <Paper elevation={3} sx={{ padding: innerPadding, display: "flex", justifyContent: "center", width: "100%" }}>
                                        <ResponsiveContainer aspectRatio={"50%"} gap={innerPaddingVal * 1.5}>
                                            <BarChartProgress pc={pc} />
                                        </ResponsiveContainer>
                                    </Paper>
                                    <Paper elevation={3} sx={{ padding: innerPadding, display: "flex", justifyContent: "center", width: `calc(50% - ${innerPaddingVal / 2}px)` }}>
                                        <ResponsiveContainer aspectRatio={"100%"}>
                                            <DoughnutChart pc={pc} onlyImperatives={true} />
                                        </ResponsiveContainer>
                                    </Paper>
                                    <Paper elevation={3} sx={{ padding: innerPadding, display: "flex", justifyContent: "center", width: `calc(50% - ${innerPaddingVal / 2}px)` }}>
                                        <ResponsiveContainer aspectRatio={"100%"} >
                                            <DoughnutChart pc={pc} onlyImperatives={false} />
                                        </ResponsiveContainer>
                                    </Paper>
                                </Box>
                            </Box>
                            <Box sx={{ width: { xs: `100%`, sm: `calc(600px - ${innerPadding})` } }}>
                                <Paper elevation={3} sx={{ padding: innerPadding }}>
                                    <ResponsiveContainer aspectRatio={"100%"} maxHeight={`calc(600px - ${innerPadding} * 3 )`}>
                                        <RadarChart pc={pc} chapterNumber={1} />
                                    </ResponsiveContainer>
                                </Paper>
                            </Box>
                            <Box sx={{ width: { xs: `100%`, sm: `calc(600px - ${innerPadding})` } }}>
                                <Paper elevation={3} sx={{ padding: innerPadding, display: "flex", justifyContent: "center" }}>
                                    <ResponsiveContainer aspectRatio={"100%"}>
                                        <RadarChart pc={pc} chapterNumber={2} />
                                    </ResponsiveContainer>
                                </Paper>
                            </Box>
                            <Box sx={{ width: { xs: `100%`, sm: `calc(600px - ${innerPadding})` } }} >
                                <Paper elevation={3} sx={{ padding: innerPadding, display: "flex", justifyContent: "center" }}>
                                    <ResponsiveContainer aspectRatio={"100%"}>
                                        <RadarChart pc={pc} chapterNumber={3} />
                                    </ResponsiveContainer>
                                </Paper>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box >
        </>
    );
};

export default EvalDashboard;
