import { HttpProvider } from './HttpProvider';
import { UserProvider } from './UserProvider';
import { PathsProvider } from './PathsProvider';
import { LeftMenuProvider } from './LeftMenuProvider';
import { ToastProvider } from './ToastProvider';
import { DialogProvider } from './DialogProvider';
import { ThemeProvider } from '@mui/material/styles';
import { MfaProvider } from './MfaProvider';
import { getThemeOptions } from '../theme/theme';
import { createTheme } from '@mui/material/styles';
import { useState, useRef } from 'react';
import { ThemeToggleContext } from './ThemeToggleContext';

function AppProviders({ children }) {
    const [isProdThemeToggled, setIsProdThemeToggled] = useState(false);
    const timeoutRef = useRef(null);

    const toggleProdTheme = (value) => {
        if (value) {
            // Clear any existing timeout before setting a new one
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
            // Set a timeout to reset the theme
            timeoutRef.current = setTimeout(() => {
                setIsProdThemeToggled(false);
            }, 180000);//3 mins in ms
            setIsProdThemeToggled(true);
        } else {
            // Clear the timeout if it's currently active
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
                timeoutRef.current = null; // Reset the timeout reference
            }
            setIsProdThemeToggled(false);
        }
    }

    return (
        <ThemeToggleContext.Provider value={{ toggleProdTheme, isProdThemeToggled }}>
            <ThemeProvider theme={createTheme(getThemeOptions(isProdThemeToggled))}>
                <ToastProvider>
                    <DialogProvider>
                        <HttpProvider>
                            <UserProvider>
                                <MfaProvider>
                                    <PathsProvider>
                                        <LeftMenuProvider>
                                            {children}
                                        </LeftMenuProvider>
                                    </PathsProvider>
                                </MfaProvider>
                            </UserProvider>
                        </HttpProvider>
                    </DialogProvider>
                </ToastProvider>
            </ThemeProvider>
        </ThemeToggleContext.Provider>
    );
}

export default AppProviders;