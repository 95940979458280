import { Zoom } from '@mui/material';
import { colors, getColor } from "./colors";
import { getEnv } from "../config";



const getPrimaryColor = (env) => {
    switch (env) {
        case "PROD": return { main: getColor(colors.forest.main), light: getColor(colors.forest.tint1) };
        case "QUAL": return { main: getColor(colors.azure.main), light: getColor(colors.azure.tint1) };
        case "DEV": return { main: getColor(colors.orange.main), light: getColor(colors.orange.tint1) };
        default: return { main: getColor(colors.greenLemon.main), light: getColor(colors.greenLemon.tint1) };
    }
}

//toggleProdTheme is Boolean. If true, primary color set to prod primary color, env color otherwise.
const getThemeOptions = (toggleProdTheme) => {
    const env = getEnv();
    return ({
        appbarHeight: "64px",
        palette: {
            primary: toggleProdTheme ? getPrimaryColor("PROD") : getPrimaryColor(env),
            secondary: { main: getColor(colors.azure.main), light: getColor(colors.azure.tint1) },
            warning: { main: getColor(colors.orange.main), light: getColor(colors.orange.tint1) },
            error: { main: getColor(colors.pink.main), light: getColor(colors.pink.tint1) },
            success: { main: getColor(colors.greenLemon.main), light: getColor(colors.greenLemon.tint1) },
        },
        components: {
            MuiTooltip: {
                defaultProps: {
                    TransitionComponent: Zoom,
                    arrow: true,
                    disableInteractive: true
                },
            },
            MuiButton: {
                styleOverrides: {
                    root: {
                        textTransform: 'none',
                        fontSize: "15px",
                        padding: '4px 16px',
                    },
                },
            },
            typography: {
                fontFamily: 'montserrat, Arial, sans-serif',
                fontWeightRegular: 450,
                fontWeightBold: 700
            },
            // MuiTableHead: {
            //     styleOverrides: {
            //         root: {
            //             '& .MuiTableCell-root': {
            //                 backgroundColor: getColor(colors.forest.main), // Apply background color to head cells
            //             },
            //         },
            //     },
            // },
            // MuiTableCell: {
            //     styleOverrides: {
            //         head: {
            //             color: '#ffffff', // Set head cell text color to white
            //         },
            //     },
            // },
            // MuiIconButton: {
            //     styleOverrides: {
            //         root: {
            //             '&.Mui-active': {
            //                 color: getColor(colors.orange.main), // Set active icons to warning color (for sorting/filtering)
            //             },
            //         },
            //     },
            // },
            // MuiTableBody: {
            //     styleOverrides: {
            //         root: {
            //             '& .MuiTableRow-root': {
            //                 borderBottom: `1px solid ${getColor(colors.forest.main)}`, // Set horizontal lines to primary color
            //             },
            //         },
            //     },
            // },
        }
    })
};


export { getThemeOptions };